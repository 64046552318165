/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Modal as BootstrapModal, Spinner, Col, Modal, Badge, Row } from "react-bootstrap";
import { Card } from "@/shared/components/Card";
import { Button } from "@/shared/components/Button";
import Tooltip from "@/shared/components/Tooltip";
import {
  colorAdditional,
  colorBlue,
  colorBorder,
  colorAccent,
} from "@/utils/palette";
import { paddingLeft, left } from "@/utils/directions";
import { SidebarLinkIcon } from "../../../Layout/components/sidebar/SidebarLink";
import { SpinnerDiv } from "../../../Dashboards/Operations/Contract/component/ContractTable";
import {
  getUserInfo,
  getLocation,
  uploadProfile,
  createSubWallet,
  getResultKYC,
  startKYC,
} from "../../../../utils/services/user.service";
import { ProfileCard } from "../ProfileBasicComponents";
import { toast } from "react-toastify";
import { useChainContext } from "../../../context/context";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Link, useHistory } from "react-router-dom";
import { FaTelegramPlane } from 'react-icons/fa';
import AddTelegramModal from "./AddTelegramModal";
import GuideInstructionModal from "./GuideInstructionalModal";

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const ProfileMain = () => {
  const [userInfo, setuserInfo] = useState([]);
  const [load, setload] = useState(false);
  const [solAddress, setSolAddress] = useState("");
  const [btcAddress, setBtcAddress] = useState("");
  const [trcAddress, setTrcAddress] = useState("");
  const [userLocationData, setUserLocationData] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const history = useHistory();
  const [twoFactors, setTwoFactor] = React.useState(false);
  const [twoFactors2, setTwoFactor2] = useState(false);
  const { refreshUserData, setRefreshUserData } = useChainContext();
  const [image1, setImage1] = useState(null);
  const [show1, setShow1] = useState(false);
  const [showGuide, setShowGuideModal] = useState(false);
  const [subWalletDetail, setSubWalletDetail] = useState(null);
  const handleClose1 = () => setShow1(false);
  const Ava = `/img/avat.png`;

  const fileInputRef = useRef(null);

  const [cropper, setCropper] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages1, setSelectedImages1] = useState([]);
  const [kycDetail, setKYCDetail] = useState('');
  const [kycStatus, setStatus] = useState('');
  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const getUsers = () => {
    setload(true);
    getUserInfo()
      .then((res) => {
        setSelectedImage(res?.data?.User.image);
        // console.log('getUsers >>>>>', res);
        if (res.status === 200) {
          // console.log('res', res);
          setload(false);
          setuserInfo(res.data.User);
          setTwoFactor(res.data.User?.status_2fa);
          setTwoFactor2(res.data.User?.TFA === true);
        } else {
          setload(false);
          setuserInfo(JSON.parse(localStorage.getItem("user")));
          setTwoFactor(userInfo?.data?.user?.status_2fa);
        }
      })
      .catch(() => {
        setuserInfo(JSON.parse(localStorage.getItem("user")));
        setload(false);
      });
  };

  const getUserAddress = () => {
    const storedData = JSON.parse(localStorage.getItem("user"));
    const solAddressFromStorage = storedData?.data?.user?.sol_address || "";
    setSolAddress(solAddressFromStorage);

    const btcAddressFromStorage = storedData?.data?.user?.btcAddress || "";
    const trcAddressFromStorage = storedData?.data?.user?.trc_address || "";
    setBtcAddress(btcAddressFromStorage);
    setTrcAddress(trcAddressFromStorage);
  };

  const fetchUserLocation = async () => {
    try {
      const res = await getLocation();
      setUserLocationData(res?.data.data);
    } catch (error) {

    }
  };

  useEffect(() => {
    getUsers();
    getUserAddress();
    fetchUserLocation();
  }, [reFetch]);
  const onCopyWalletAddress = (item) => {
    navigator.clipboard.writeText(item);
    toast.success('Address Copied !')
  };

  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  };
  const handleCrop = async () => {
    setload(true);
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      const dataUrl = croppedCanvas.toDataURL();
      const blob = dataURLtoBlob(dataUrl);
      const croppedFile = new File([blob], "cropped-image.jpg", {
        type: "image/jpeg",
      });

      const blobUrl = URL.createObjectURL(blob);

      if (!selectedImages1.includes(blobUrl)) {
        setSelectedImages(Array.from(croppedFile));
        setSelectedImages1((prevImages) => [...prevImages, croppedFile]);
        const formData = new FormData();
        formData.append("File", croppedFile);
        const res = await uploadProfile(formData);
        toast.success(res.data.message);
        getUsers();
        setRefreshUserData(!refreshUserData);
        handleClose1();
        setload(false);
      }
    }
  };

  const handleButtonClickClick1 = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const selectedImagesArray = e.target.files;
    const selectedImage = e.target.files[0];
    setImage1(URL.createObjectURL(selectedImage));

    setShow1(true);
  };


  const createSubWalletUser = async () => {
    try {
      setload(true)
      const res = await createSubWallet()
      setSubWalletDetail(res.data)
      toast.success(res?.data?.message || res?.data?.Message)
      getUsers()
      setload(false)

    } catch (error) {
      toast.error(error?.response?.data.Message);
      setload(false)
    }
  }

  return (
    <Col md={12} lg={12} xl={12} className='profileCards'>
      <Card>
        <ProfileCard>
          <ProfileInformation>
            {load ? (
              <SpinnerDiv className="py-1" style={{ height: "auto" }}>
                <Spinner style={{ color: colorAccent }} />
              </SpinnerDiv>
            ) : (
              <div className="d-flex flex-column w-100 ">
                <div className="d-flex flex-row w-100 justify-content-center align-items-center  gap-3">
                  <div className="d-flex flex-column gap-2">
                    <div
                      className="profile-avatar position-relative  rounded-4 d-flex flex-row justify-content-center   "
                      onClick={handleButtonClickClick1}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        style={{
                          width: "110px",
                          height: "110px",
                          objectFit: "cover",
                          borderRadius: '50%'
                        }}
                        src={selectedImage || Ava}
                        alt="avatar"
                        className="d-flex align-self-center justify-content-center " />
                      <div className="edit-icon position-absolute top-50 start-50 translate-middle">
                        <i
                          className="bi bi-pencil text-white "
                          style={{ fontSize: "1rem" }}
                        ></i>
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}

                        style={{ display: "none" }}
                        onChange={handleImageUpload} />

                    </div>

                  </div>
                </div>
                <div>
                  <ProfileData>

                    <Row className="mt-4">
                      {/* user details column  */}
                      <Col sm={12} md={4} >
                        <ProfileContact>
                          <strong>Name:</strong> <br />
                          {userInfo.firstName || userInfo?.data?.user?.firstName}{" "}
                          {userInfo.lastName || userInfo?.data?.user?.lastName}
                          <ProfileName>
                          </ProfileName>
                        </ProfileContact>
                        <ProfileContact>
                          <strong>Email:</strong> <br />

                          {userInfo.email || userInfo?.data?.user?.email}
                        </ProfileContact>

                        {userInfo?.complete_address &&
                          <ProfileContact style={{ fontSize: "small" }}>
                            <strong>Address:</strong> <br />
                          </ProfileContact>}

                        {userInfo?.profile_document?.length > 0 && <ProfileContact className="d-flex flex-column align-items-md-start gap-2" style={{ fontSize: "small" }}>
                          <strong>Document:</strong>
                          <HashLink onClick={() => {
                            window.open(userInfo.profile_document[0])
                          }}>
                            <Button variant='outline-primary' className='py-1'>
                              View
                            </Button>
                          </HashLink>
                        </ProfileContact>}

                        {userInfo?.phone_number && <ProfileContact style={{ fontSize: "small" }}>
                          <strong>Phone Number:</strong> <br />
                          {userInfo?.phone_number}
                        </ProfileContact>}

                        {userInfo?.vat_number && <ProfileContact style={{ fontSize: "small" }}>
                          <strong>Vat Number:</strong> <br />
                          {userInfo?.vat_number}
                        </ProfileContact>}

                        {userInfo?.project_company && <ProfileContact style={{ fontSize: "small" }}>
                          <strong>Project Company</strong> <br />
                          {userInfo?.project_company}
                        </ProfileContact>}
                        {userInfo?.telegram_bot_token && <ProfileContact style={{ fontSize: "small" }}>
                          <strong>Telegram Bot Token</strong> <br />
                          <span>
                            {`${userInfo?.telegram_bot_token.slice(0, 10)}...${userInfo?.telegram_bot_token.slice(-5)}`}
                            <Tooltip text="Copy Address" placement="top" style={{ cursor: "pointer" }}>
                              <SidebarLinkIcon
                                style={{ cursor: "pointer" }}
                                className="ml-1 lnr lnr-book"
                                onClick={() => onCopyWalletAddress(userInfo?.telegram_bot_token)}
                              />
                            </Tooltip>
                          </span>
                        </ProfileContact>}
                        {userInfo?.channel_id && <ProfileContact style={{ fontSize: "small" }}>
                          <strong>Telegram Channel ID</strong> <br />



                          <span>
                            {`${userInfo?.channel_id.slice(0, 10)}...${userInfo?.channel_id.slice(-5)}`}
                            <Tooltip text="Copy Address" placement="top" style={{ cursor: "pointer" }}>
                              <SidebarLinkIcon
                                style={{ cursor: "pointer" }}
                                className="ml-1 lnr lnr-book"
                                onClick={() => onCopyWalletAddress(userInfo?.channel_id)}
                              />
                            </Tooltip>
                          </span>
                        </ProfileContact>}

                        {userInfo?.website && <ProfileContact style={{ fontSize: "small" }}>
                          <strong>Website</strong> <br />
                          {userInfo?.website}
                        </ProfileContact>}
                      </Col>

                      {/* user wallet Addresses  */}
                      <Col sm={12} md={4} className="border-start">
                        <ProfileContact style={{ fontSize: "small" }}>

                          <strong>Virtual Wallet</strong> <br />
                          <p>BNB/ETH/MATIC :</p>
                          {`${userInfo?.address?.slice(
                            0,
                            15
                          )}...${userInfo?.address?.slice(-5)}` ||
                            `${userInfo?.data?.user?.address?.slice(
                              0,
                              15
                            )}...${userInfo?.data?.user?.address?.slice(-5)}`}

                          <Tooltip
                            text="Copy Address"
                            placement="top"
                            style={{ cursor: "pointer" }}
                          >
                            <SidebarLinkIcon
                              style={{ cursor: "pointer" }}
                              className="ml-1 lnr lnr-book"
                              onClick={() =>
                                onCopyWalletAddress(
                                  userInfo.address || userInfo?.data?.user?.address
                                )
                              }
                            />
                          </Tooltip>

                          {solAddress && (
                            <div className="py-2">
                              <b>SOL Address:</b>
                              <span>
                                {`${solAddress.slice(0, 10)}...${solAddress.slice(-5)}`}
                                <Tooltip text="Copy Address" placement="top" style={{ cursor: "pointer" }}>
                                  <SidebarLinkIcon
                                    style={{ cursor: "pointer" }}
                                    className="ml-1 lnr lnr-book"
                                    onClick={() => onCopyWalletAddress(solAddress)}
                                  />
                                </Tooltip>
                              </span>
                            </div>
                          )}
                          {userInfo?.secondary_wallet_address && (
                            <div className="py-2">
                              <b>Onchain Address:</b> <br />
                              <p>BNB/ETH/MATIC :</p>

                              <span>
                                {`${userInfo?.secondary_wallet_address.slice(0, 10)}...${userInfo?.secondary_wallet_address.slice(-5)}`}
                                <Tooltip text="Copy Address" placement="top" style={{ cursor: "pointer" }}>
                                  <SidebarLinkIcon
                                    style={{ cursor: "pointer" }}
                                    className="ml-1 lnr lnr-book"
                                    onClick={() => onCopyWalletAddress(userInfo?.secondary_wallet_address)}
                                  />
                                </Tooltip>
                              </span>
                            </div>
                          )}

                          {btcAddress && (
                            <div className="py-2">
                              <b>BTC Address:</b>
                              <span>
                                {`${btcAddress.slice(0, 15)}...${btcAddress.slice(-5)}`}
                                <Tooltip text="Copy Address" placement="top" style={{ cursor: "pointer" }}>
                                  <SidebarLinkIcon
                                    style={{ cursor: "pointer" }}
                                    className="ml-1 lnr lnr-book"
                                    onClick={() => onCopyWalletAddress(btcAddress)}
                                  />
                                </Tooltip>
                              </span>
                            </div>
                          )}
                          {trcAddress && (
                            <div className="py-2">
                              <b>TRON Address:</b>
                              <span>
                                {`${trcAddress.slice(0, 10)}...${trcAddress.slice(-5)}`}
                                <Tooltip text="Copy Address" placement="top" style={{ cursor: "pointer" }}>
                                  <SidebarLinkIcon
                                    style={{ cursor: "pointer" }}
                                    className="ml-1 lnr lnr-book"
                                    onClick={() => onCopyWalletAddress(trcAddress)}
                                  />
                                </Tooltip>
                              </span>
                            </div>
                          )}

                        </ProfileContact>
                      </Col>

                      {/* All Buttons  */}
                      <Col sm={12} md={4} className="border-start">
                        <div className="d-flex flex-column d-md-flex flex-md-row gap-2 ">

                          <ProfileContact style={{ fontSize: "small" }}>
                            <div className="d-flex flex-column">
                              <FormButton variant="outline-primary" type="submit"
                                onClick={createSubWalletUser}
                                disabled={userInfo?.secondary_wallet_address}
                              >
                                Onchain Wallet
                              </FormButton>

                              <FormButton variant="outline-primary" type="submit"
                                onClick={() => setShowTelegramModal(true)}
                                disabled={userInfo?.telegram_bot_token}
                              >
                                <div className="d-flex gap-2 align-items-center">
                                  {" Link Telegram"}  <FaTelegramPlane size={40} color="#0088cc" />
                                </div>
                              </FormButton>
                              <FormButton variant="outline-primary" type="submit"
                                onClick={() => setShowGuideModal(true)}

                              >
                                <div className="d-flex gap-2 align-items-center">
                                  Telegram Account Guide <FaTelegramPlane size={40} color="#0088cc" />
                                </div>
                              </FormButton>
                              {/* <FormButton variant="outline-primary" type="submit"
                              // onClick={createSubWalletUser}
                              // disabled={userInfo?.secondary_wallet_address}
                              >
                                Request Project
                              </FormButton> */}
                            </div>
                          </ProfileContact>

                        </div>
                      </Col>
                    </Row>

                  </ProfileData>

                </div>

              </div>
            )}
          </ProfileInformation>
        </ProfileCard>
      </Card>

      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        show={show1}
        onHide={handleClose1}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            Crop Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x mb-1 p-0">
            <div
              className="card mt-0"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <Cropper
                      src={image1}
                      aspectRatio={12 / 12}
                      guides
                      // crop={e => console.log(e.detail)}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                    />
                    <Button
                      onClick={handleCrop}
                      disabled={load}
                      size="md"
                      className="py-2 btn-block mt-2"
                      variant="success"
                    >
                      Upload Image
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <GuideInstructionModal show={showGuide} hide={() => setShowGuideModal(false)} />

      <AddTelegramModal reFetch={() => setRefetch(!reFetch)} show={showTelegramModal} hide={() => setShowTelegramModal(false)} />
    </Col>
  );
};

export default ProfileMain;

// region STYLES

const ProfileInformation = styled.div`
  padding: 30px 10px;
  display: flex;
  text-align: ${left};
  border-bottom: 1px solid ${colorBorder};

  @media (max-width: 1345px) and (min-width: 1200px) {
    padding: 30px 15px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const ProfileAvatar = styled.div`
  height: 110px;
  width: 140px;
  overflow: hidden;
  border-radius: 50%;

  img {
    height: 100%;
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    height: 130px;
    width: 130px;
  }
`;
const FormButton = styled(Button)`
 color:"white";
 mergin-top:1rem;
 `;
const ProfileData = styled.div`
  width: calc(100% - 0px);
  // ${paddingLeft}: 25px;

  @media screen and (max-width: 360px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
`;

const ProfileName = styled.p`
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  line-height: 18px;
`;

const ProfileWork = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
`;

const ProfileContact = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 18px;
`;

const ProfileButton = styled(Button)`
  margin-top: 10px;
  margin-bottom: 0;
  padding: 8px 15px;
`;

const ProfileStats = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ProfileStat = styled.div`
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
`;

const ProfileStatNumber = styled.p`
  color: ${colorBlue};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
`;

const ProfileStatTitle = styled.p`
  margin: 0;
  color: ${colorAdditional};
  font-size: 12px;
  line-height: 14px;
`;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
// endregion
