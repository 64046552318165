import React, { createContext, useContext, useState } from "react";

const ChainContext = createContext();

export const ChainProvider = ({ children }) => {
  const [selectedInternalChainOption, setSelectedInternalChainOption] =
    useState("BNB");
  const [selectedExternalChainOption, setSelectedExternalChainOption] =
    useState("BNB");
  const [chainLoading, setChainLoading] = useState(false);
  const [externalBalance, setExternalBalance] = useState("");
  const [refreshInternalBalace, setRefreshInternalBalance] = useState(false);
  const [refreshUserData, setRefreshUserData] = useState(false);
  const [currentCalendarData, setCurrentCalendarDate] = useState(new Date());
  const [selectRole, setSelectRole] = useState("");
  const [selectedDashboardCoin, setSelectedDashboardCoin] =
    useState("internal");
  const [socket, setSocket] = useState(null);
  const [reFethData, setReFetchData] = useState(false);
  const [reFetchActivities, setRefetchActivities] = useState(false);
  const [globalProjectOtcId, setGlobalProjectOtcId] = useState("");
  const [globalProjectOwnerId, setGlobalProjectOwnerId] = useState("");
  const [infoData, setInfoData] = useState([]);
  const [globalTokens, setGlobaltoken] = useState([]);
  const [globalTokensLogo, setGlobaltokenLogo] = useState([]);

  const updateSelectedInternalChain = (newChain) => {
    setSelectedInternalChainOption(newChain);
  };

  const updateSelectedExternalChain = (newChain) => {
    setSelectedExternalChainOption(newChain);
  };

  return (
    <ChainContext.Provider
      value={{
        reFethData,
        setReFetchData,
        refreshInternalBalace,
        setRefreshInternalBalance,
        chainLoading,
        setChainLoading,
        selectedInternalChainOption,
        updateSelectedInternalChain,
        selectedExternalChainOption,
        updateSelectedExternalChain,
        setExternalBalance,
        externalBalance,
        refreshUserData,
        setRefreshUserData,
        currentCalendarData,
        setCurrentCalendarDate,
        selectRole,
        setSelectRole,
        setSelectedDashboardCoin,
        selectedDashboardCoin,
        socket,
        setSocket,
        reFetchActivities,
        setRefetchActivities,
        globalProjectOtcId,
        setGlobalProjectOtcId,
        globalProjectOwnerId,
        setGlobalProjectOwnerId,
        infoData,
        setInfoData,
        globalTokens,
        setGlobaltoken,
        globalTokensLogo,
        setGlobaltokenLogo,
      }}
    >
      {children}
    </ChainContext.Provider>
  );
};

export const useChainContext = () => {
  const context = useContext(ChainContext);
  if (!context) {
    throw new Error("useChainContext must be used within a ChainProvider");
  }
  return context;
};
