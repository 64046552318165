/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import "cropperjs/dist/cropper.css";
import Select from "react-select";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Panel from "@/shared/components/Panel";

import { Button } from "../../../shared/components/Button";
// import "../style/CreateProject.css";
import { Formik } from "formik";
import { createProject, getExternalStoreFee, getFee, myrequestStatus } from "../../../utils/services/user.service";
import { useChainContext } from "../../../containers/context/context";

const CreateProject = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [myRequestStatus, setMyRequestStatus] = useState('');
    const { setReFetchData, reFethData } = useChainContext()
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const [values, setValues] = useState({
        title: "",
        website: "",
        description: "",
        email: "",
        phone_number: "",
        explorer_link: "",
    });
    const [withdrawFee, setElectionFee] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImageFile(file)
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
        }
    };

    const removeImage = () => {
        setSelectedImage(null);
    };

    const handleCreateProject = async (val) => {
        setIsLoading(true); // Show loading indicator
        try {
            const formData = new FormData();
            // Append form values
            formData.append("name", val?.title || "");
            formData.append("File", selectedImageFile || null); // Banner image
            formData.append("File2", val?.Documents[0] || []); // PDF documents
            formData.append("website", val?.website || "");
            formData.append("description", val?.description || "");
            formData.append("email", val?.email || "");
            formData.append("phone_number", val?.phone_number || "");
            formData.append("explorer_link", val?.explorer_link || "");
            formData.append("insta_link", val?.insta_link || "");
            formData.append("telegram_link", val?.telegram_link || "");
            formData.append("x_link", val?.x_link || "");

            // Handle `locations` (string or array)
            const locations = Array.isArray(val?.locations) ? JSON.stringify(val.locations) : val.locations || "";
            formData.append("locations", locations);

            // API call
            const res = await createProject(formData);

            // Handle success response
            toast.success(res?.data?.Message || "Project created successfully!");
            setReFetchData((prev) => !prev); // Toggle refetch state
            getmyrequestStatus(); // Fetch request status
        } catch (error) {
            const errorMessage = error.response?.data?.Message || "Something went wrong";
            toast.error(errorMessage);
            console.error("🚀 ~ handleCreateProject ~ error:", error);
        } finally {
            setIsLoading(false); // Hide loading indicator
        }
    };




    const getmyrequestStatus = async () => {
        setIsLoading(true)
        try {
            const res = await myrequestStatus()
            setMyRequestStatus(res?.data?.req_status)
            setIsLoading(false)

        } catch (error) {
            console.log("🚀 ~ getmyrequestStatus ~ error:", error)
            setIsLoading(false)

        }
    }
    useEffect(() => {
        getmyrequestStatus()
        fetchgetFee()
    }, [])

    const socialLinkValidators = {
        insta_link: /^(https?:\/\/)?(www\.)?instagram\.com\/.+$/,
        telegram_link: /^(https?:\/\/)?(t\.me\/|telegram\.me\/).+$/,
        x_link: /^(https?:\/\/)?(www\.)?twitter\.com\/.+$/,
    };
    const validateSocialLink = (field, value) => {
        const pattern = socialLinkValidators[field];
        if (pattern && !pattern.test(value)) {
            return false; // Invalid link
        }
        return true; // Valid link
    };
    const fetchgetFee = async () => {
        setIsLoading(true);
        try {
          const requestData = {
            type: 'project_fee',
          };
          const response = await getExternalStoreFee(requestData);
          setElectionFee(response.data.data)
          setIsLoading(false);
    
        } catch (error) {
          setIsLoading(false);    
        }
      };


    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <Panel lg={12} title="Create Project" closeBtn minusBtn refreshLoad>
                {!myRequestStatus ? (
                    <Formik
                        initialValues={{
                            title: "",
                            website: "",
                            description: "",
                            email: "",
                            phone_number: "",
                            explorer_link: "",
                            insta_link: "",
                            telegram_link: "",
                            x_link: "",
                            File: null,
                            Documents: [],
                        }}
                        onSubmit={(values, { resetForm }) => {

                            handleCreateProject(values);
                            resetForm();
                        }}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            {showAlert && (
                                                <div className="alert alert-danger text-center" role="alert">
                                                    {errorMessage}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {selectedImage && (
                                        <div>
                                            <img
                                                src={selectedImage}
                                                alt={`Banner`}
                                                style={{
                                                    width: "100%",
                                                    marginBottom: "10px",
                                                    minHeight: "400px",
                                                    maxHeight: "400px",
                                                    objectFit: "cover",
                                                    filter: "blur(1px)",
                                                }}
                                            />
                                            <Button
                                                onClick={removeImage}
                                                variant={"outline-danger"}
                                            >
                                                Remove Banner
                                            </Button>
                                        </div>
                                    )}

                                    <div className="row">
                                        {["title", "website", "description", "email", "phone_number", "explorer_link", "insta_link", "telegram_link", "x_link"].map((field) => (
                                            <div
                                                key={field}
                                                className="col-md-12 col-lg-4 col-sm-12 mb-2"
                                            >
                                                <InputDiv>
                                                    <label
                                                        htmlFor={field}
                                                        style={{ fontSize: "small" }}
                                                        className="form-label text-white"
                                                    >
                                                        {field.replace("_", " ").replace(/\b\w/g, (c) => c.toUpperCase())}
                                                    </label>
                                                    <div>
                                                        <ChatSearchInput
                                                            type="text"
                                                            value={values[field]}
                                                            name={field}
                                                            id={field}
                                                            placeholder={`Enter ${field.replace("_", " ")}`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={`form-control mt-2 ${errors[field] && touched[field] && "error"}`}
                                                        />
                                                        {errors[field] && touched[field] && (
                                                            <Error className="input feedback">
                                                                {errors[field]}
                                                            </Error>
                                                        )}
                                                    </div>
                                                </InputDiv>
                                            </div>
                                        ))}

                                        <div className="col-md-12 col-lg-4 col-sm-12 mb-2">
                                            <InputDiv>
                                                <label
                                                    htmlFor="File"
                                                    style={{ fontSize: "small" }}
                                                    className="form-label text-white"
                                                >
                                                    Upload Banner
                                                </label>
                                                <div>
                                                    <input
                                                        type="file"
                                                        id="File"
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            setFieldValue("File", file);
                                                            handleImageChange(e);
                                                        }}
                                                    />
                                                    <Button
                                                        type="button"
                                                        size="md"
                                                        className="py-2 btn-block"
                                                        variant="outline-info"
                                                        onClick={() => document.getElementById("File").click()}
                                                        disabled={selectedImage}
                                                    >
                                                        Choose Banner Image
                                                    </Button>
                                                </div>
                                            </InputDiv>
                                        </div>

                                        <div className="col-md-12 col-lg-4 col-sm-12 mb-2">
                                            <InputDiv>
                                                <label
                                                    htmlFor="Documents"
                                                    style={{ fontSize: "small" }}
                                                    className="form-label text-white"
                                                >
                                                    Upload Documents
                                                </label>
                                                <div>
                                                    <input
                                                        type="file"
                                                        id="Documents"
                                                        accept="application/pdf"
                                                        multiple
                                                        style={{ display: "none" }}
                                                        onChange={(e) => {
                                                            const files = Array.from(e.target.files);
                                                            setFieldValue("Documents", [...values.Documents, ...files]);
                                                        }}
                                                    />
                                                    {values.Documents.length > 0 && (
                                                        <ul className="text-white">
                                                            {values.Documents.map((file, index) => (
                                                                <li key={index} className="d-flex justify-content-between">
                                                                    {file.name}
                                                                    <Button
                                                                        variant="outline-danger"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            setFieldValue(
                                                                                "Documents",
                                                                                values.Documents.filter((_, i) => i !== index)
                                                                            );
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    <Button
                                                        type="button"
                                                        size="md"
                                                        className="py-2 btn-block"
                                                        variant="outline-info"
                                                        onClick={() => document.getElementById("Documents").click()}
                                                    >
                                                        Choose Documents
                                                    </Button>
                                                </div>
                                            </InputDiv>
                                        </div>
                                    </div>
                                    <FeeMessage>
                                        Note:The fee for creating a project is <span className="text-success">{withdrawFee?.fee} {withdrawFee?.symbol}</span>.
                                    </FeeMessage>
                                    <hr />

                                    <div className="row mb-4">
                                        <div className="col-md-12 col-lg-4 col-sm-12" />
                                        <div className="col-md-12 col-lg-4 col-sm-12">
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Button
                                                    type="submit"
                                                    size="md"
                                                    className="py-2 btn-block"
                                                    variant="outline-success"
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-4 col-sm-12" />
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>



                ) : (
                    <h5>Your request has been submitted. Please wait for any action from the admin.</h5>
                )}
            </Panel>




        </>
    );
};

export default CreateProject;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;


const FeeMessage = styled.div`
  font-size:12px;
  color:yellow;
  text-align: start;
  margin: 1rem 0;
`;