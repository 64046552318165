/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { right } from "@/utils/directions";
import Panel from "@/shared/components/Panel";
import { Table } from "@/shared/components/TableElements";
import { fetchTopTenCurrencies } from "@/redux/actions/topTenActions";
import { getTokenDetailsWithPrice } from "../../../../utils/services/user.service";
import { Bullet } from "../../BasicDashboardComponents";
import numeral from "numeral";
import { optionColors } from "../../../../utils/constants/contants";
import { SidebarLinkIcon } from "../../../Layout/components/sidebar/SidebarLink";
import Tooltip from "@/shared/components/Tooltip";
import { toast } from "react-toastify";
import { useChainContext } from "../../../context/context";

const TradeHistory = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [allTokensDetail, setAlltokenDetails] = useState([]);
  const { globalTokensLogo } = useChainContext()

  const refreshData = () => {
    dispatch(fetchTopTenCurrencies());
  };

  useEffect(() => {
    const getTokenDetails = async () => {
      try {
        const res = await getTokenDetailsWithPrice();
        setAlltokenDetails(res?.data?.data);
      } catch (error) {
        console.log("🚀 ~ getTokenDetails ~ error:", error);
      }
    };

    getTokenDetails();
  }, []);

  const copyContractAddress = (item) => {
    navigator.clipboard.writeText(item);
    toast.success("Text Copied");
  };

  const matchTokenUrl = (tokenName) => {
    let normalizedTokenName = tokenName.toUpperCase() === "USDT (BEP20)"
      ? "tether"
      : tokenName.toLowerCase();
    if (normalizedTokenName.includes("(sol)") || normalizedTokenName.includes("(erc20)")) {
      normalizedTokenName = normalizedTokenName.split(" ")[0]; // Extract the base token name
    }

    if (normalizedTokenName === "btc") {
      normalizedTokenName = "bitcoin";
    }
    else if (tokenName === "CTY") {
      normalizedTokenName = "CustodyCoin";
    }
    if (tokenName === "EUROC") {
      normalizedTokenName = "eurc";
    }
    normalizedTokenName = normalizedTokenName.replace(/[^a-zA-Z0-9]/g, "");
    const matchedToken = globalTokensLogo.find(token =>
      token.url.toLowerCase().includes(normalizedTokenName)
    );

    return matchedToken?.url || ""; // Return the matched URL or an empty string
  };
  return (
    <StyledPanel
      xl={12}
      xs={12}
      className="mt-3 pb-2"
      title="Coins"
      subhead="Current Prices"
      refreshRequest={refreshData}
    >
      <StyledTable responsive>
        <thead>
          <tr>
            <TradeHistoryTh>Coins</TradeHistoryTh>
            <TradeHistoryTh>Price</TradeHistoryTh>
            <TradeHistoryTh>Contract Address</TradeHistoryTh>
          </tr>
        </thead>
        <tbody>
          {allTokensDetail?.map((item, idx) => (
            <TableRow key={idx} style={{
              cursor: 'pointer'
            }}>
              <td>
                <DashboardCoin color={optionColors[idx].value}>
                  <Bullet color={optionColors[idx].value} />
                  <img
                    src={matchTokenUrl(item?.symbol)}
                    alt={item?.symbol}
                    style={{ width: "24px", height: "24px" }}
                  /> {item?.symbol}
                </DashboardCoin>
              </td>
              <td>
                {item?.price?.[0]?.priceUSD ? (
                  <b>${numeral(item.price[0].priceUSD).format("0,0.00")}</b>
                ) : (
                  <b>$0.00</b>
                )}
              </td>
              <td>
                <AddressContainer>
                  {item?.contract_address || item?.symbol}
                  <Tooltip text="Copy Contract Address" placement="top">
                    <IconButton
                      onClick={() =>
                        copyContractAddress(item?.contract_address || item?.symbol)
                      }
                    >
                      <SidebarLinkIcon className="lnr lnr-book" />
                    </IconButton>
                  </Tooltip>
                </AddressContainer>
              </td>
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </StyledPanel>
  );
};

export default TradeHistory;

// Styled Components
const StyledPanel = styled(Panel)`
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  // padding: 20px;
  // background: #ffffff;
`;

const StyledTable = styled(Table)`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  th,
  td {
    padding: 12px 16px;
    text-align: left;
  }

  th {
    background: #f8f9fa;
    font-weight: 600;
    color: #495057;
    border-bottom: 2px solid #e9ecef;
  }

  td {
    background: #ffffff;
    border-bottom: 1px solid #e9ecef;
  }
`;

const TableRow = styled.tr`
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const DashboardCoin = styled.p`
  color: ${(props) => props.color};
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TradeHistoryTh = styled.th`
  font-weight: 600;
  color: #495057;
`;

const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
  transition: color 0.2s ease;

  &:hover {
    color: #495057;
  }
`;