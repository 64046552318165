/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import '../style/Rating.css';
import { Carousel } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { Button } from '@/shared/components/Button';
import { CardBody } from '@/shared/components/Card';
import { colorAccent, colorAdditional, colorBackground } from '@/utils/palette';
import { right, left, marginRight } from '@/utils/directions';
import { getMyProduct, getProductData } from '../../../utils/services/user.service';
// import '../style/product.css';
import StarRating from '../home/StarRating';


const Product = ({ storeId }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { productId } = useParams();

  const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
  const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;

  function removeDuplicateColors(product) {
    const seenColors = new Set();
    // eslint-disable-next-line no-param-reassign
    product.product_sizes_quantity = product.product_sizes_quantity.filter((item) => {
      if (!seenColors.has(item.color)) {
        seenColors.add(item.color);
        return true;
      }
      return false;
    });
  }

  // Iterate through each product and remove duplicate colors
  if (products.length > 0) {
    products.forEach((product) => {
      removeDuplicateColors(product);
    });
  }

  const getProductDetail = async () => {
    try {
      const response = await getProductData({ productId }); // Pass productId to the API call
      if (response.data && response.data) {
        // Handle the received product details as needed
      } else {
        console.error('Not Found');
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };
  useEffect(() => {
    const fetchStoreData = async () => {
      setIsLoading(true)
      const payload = {
        limit: 20,
        pageNumber: 1,
        store_id: storeId,
      };
      try {
        const response = await getMyProduct(payload);
        if (response.data && response.data.products.length > 0) {
          setIsLoading(false)
          setProducts(response.data.products);
        } else {
          setIsLoading(false)
          console.error('Not Found');
        }
      } catch (error) {
        setIsLoading(false)
        console.error('Error fetching store data:', error);
      }
    };
    fetchStoreData();
  }, [storeId]);
  return (
    <div className="container mt-4">
      <div className="row px-4">
        {isLoading ? (
          // Loading Skeleton
          Array.from({ length: 6 }).map((_, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" key={index}>
              <ProductCard>
                <div className="mt-4">
                  <SkeletonImage />
                </div>
                <div className="py-4">
                  <SkeletonText />
                  <SkeletonPrice />
                </div>
              </ProductCard>
            </div>
          ))
        ) :
         products.length > 0 ? (
          // Product Cards
          products.map((product) => (
            <div
            className="col-lg-3 col-md-6 col-sm-12"
            style={{ textTransform: 'capitalize' }}
            key={product._id}
          >
            <Link to={`/productdetails?color=${product?.product_sizes_quantity[0].color}&brand=${encodeURIComponent(product.brand)}&title=${encodeURIComponent(product.title)}&id=${encodeURIComponent(product._id)}`}>
              <div className="card  justify-content-between" style={{ background: '#232329' }}>
                <div className=" mt-4">
                  <img
                    src={product.pictures[0]}
                    className=" mt-4 border-radius zoom-in-on-hover"
                    style={{
                      maxHeight: '200px',
                      objectFit: 'contain'

                    }} alt={product.storeName}
                  />
                </div>


                <div className="py-4">
                  <span className="d-flex">
                    <span style={{ width: '100%' }}>
                      <h5 className="card-title producttitle mt-0 mb-1">{product.title}</h5>
                      {(() => {
                        const newVal = 1;
                        // eslint-disable-next-line max-len
                        return <h6 className="card-title producttitle mt-0 mb-0">{product.brand}</h6>;
                      })()}
                    </span>
                    <span style={{ float: 'right', width: '40%' }}>
                      <p
                        className="card-title productPrice mb-0 float-right"
                      >
                        {product.product_sizes_quantity[0].price} {product.symbol}
                      </p>
                    </span>
                  </span>
                  <span>
                    {product.product_sizes_quantity.map(color => (
                      <ProductItemColor
                        style={{ background: color.color }}
                      />
                    ))}
                  </span>
                  <div className="Rating mb-4" style={{ float: 'right', marginTop: '1%' }}>
                    {product.ProductRatting?.length > 0 ? (
                      <StarRating rating={product.ProductRatting[0]?.averageRating} />
                    ) : (
                      <StarRating rating={0} />
                    )}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          ))
        ) : (
          // No Products Available
          <p className="text-center mb-5">No Products available.</p>
        )}
      </div>
    </div>
  );
};
export default Product;

// region STYLES


const ProductItemTitle = styled.h4`
  font-weight: 500;
  transition: all 0.3s;
`;

const ProductItemColor = styled.span`
  height: 15px;
  width: 15px;
  ${marginRight}: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 10px;
`;

const ProductItemLabel = styled.img`
  position: absolute;
  top: -2px;
  ${right}: 20px;
  width: 50px;
`;

const ProductCard = styled(Card)`
  background: #232329 !important;
  border: none;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`;

const SquareImgWrapper = styled.div`
  position: relative;
  padding-top: 100%; // 1:1 aspect ratio
  overflow: hidden;
`;

const ProductImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const SkeletonLoader = styled.div`
  background: #333;
  border-radius: 8px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

const SkeletonImage = styled(SkeletonLoader)`
  width: 100%;
  padding-top: 100%; // 1:1 aspect ratio
`;

const SkeletonText = styled(SkeletonLoader)`
  width: 60%;
  height: 20px;
  margin-bottom: 10px;
`;

const SkeletonPrice = styled(SkeletonLoader)`
  width: 40%;
  height: 20px;
`;