export const externalWalletAddresses = [
  {
    value: "0x0EFBfbD47d429bcad372930a50328BE847Bfeb76",
    symbol: "USDC",
  },
  {
    value: "0xa8f9c88808bbaCE8db49675F63F7f4AE43b6fA28",
    symbol: "PAX",
  },
  {
    value: "0x32B56a0Fd9164808132C691f7A15C7Ef04BaCD79",
    symbol: "USDT (BEP20)",
  },
  {
    value: "0xd9F494E4a782B61857DCb6B11Ff2cEbD81Cf4d54",
    symbol: "BUSD",
  },
  {
    value: "0x592Ed02b382C7d682851Ff241c016c4550E11E5a",
    symbol: "CTY",
  },
  {
    value: "0x99d6C18402009ed9945E2DA8D58B93E87e318c4b",
    symbol: "EUROC",
  },
  {
    value: "0x78190373FD5CB87EB028d476253E494bc6C2a96E",
    symbol: "GBPT",
  },
  {
    value: "0x07EE19796F68BdB86DCBEC96C6162D829DdEda2B",
    symbol: "JPYC",
  },
  {
    value: "BNB",
    symbol: "BNB",
  },
  {
    value: "0x7a656f418afc09eaF4AE8b75EAe74fE09E7A7706",
    symbol: "OPY",
  },
  {
    value: "0x500f8c7a9eE51C5b6f6b8D836d84340d7272D84E",
    symbol: "FTY",
  },
  {
    value: "0xDd770074aa548B66Ab54CF0f6E67F9354E3b732e",
    symbol: "MET$",
  },
];
export const optionColors = [
  { value: "#BDC3C7", label: "Light Gray" },
  { value: "#C0392B", label: "Red" },
  { value: "#E74C3C", label: "Light Red" },
  { value: "#9B59B6", label: "Light Purple" },
  { value: "#8E44AD", label: "Purple" },
  { value: "#2980B9", label: "Dark Blue" },
  { value: "#3498DB", label: "Light Blue" },
  { value: "#1ABC9C", label: "Sea Green" },
  { value: "#16A085", label: "Dark Sea Green" },
  { value: "#27AE60", label: "Dark Green" },
  { value: "#2ECC71", label: "Light Green" },
  { value: "#F1C40F", label: "Yellow" },
  { value: "#E67E22", label: "Dark Orange" },
  { value: "#D35400", label: "Dull Orange" },
  { value: "#ECF0F1", label: "White" },
  { value: "#F39C12", label: "Orange" },
  { value: "#95A5A6", label: "Dull Gray" },
  { value: "#7F8C8D", label: "Dark Gray" },
  { value: "#34495E", label: "Light Black" },
  { value: "#FFA07A", label: "Salmon Red" },
  { value: "#F08080", label: "Light Coral" },
  { value: "#DFFF00", label: "Basanti" },
  { value: "#DE3163", label: "Pink" },
  { value: "#40E0D0", label: "Ferozi" },
  { value: "#1ABC9C", label: "Sea Green" },
  { value: "#16A085", label: "Dark Sea Green" },
  { value: "#27AE60", label: "Dark Green" },
  { value: "#2ECC71", label: "Light Green" },
  { value: "#F1C40F", label: "Yellow" },
  { value: "#E67E22", label: "Dark Orange" },
  { value: "#D35400", label: "Dull Orange" },
  { value: "#ECF0F1", label: "White" },
  { value: "#F39C12", label: "Orange" },
];
export const pool2Options = [
  {
    label: "MATIC",
    value: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  },
  {
    label: "JPYC",
    value: "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
  },
  {
    label: "ETH",
    value: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  },
  {
    label: "USDT (ERC20)",
    value: "0x76A8387319ad56aB1D354c08c29fE6B08E708181",
  },
  {
    label: "PAX",
    value: "0x8E870D67F660D95d5be530380D0eC0bd388289E1",
  },
  {
    label: "USDC",
    value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  },
  {
    label: "GBPT",
    value: "0x86B4dBE5D203e634a12364C0e428fa242A3FbA98",
  },
  {
    label: "EUROC",
    value: "0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c",
  },
  {
    label: "BNB",
    value: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  },
  {
    label: "CTY",
    value: "0x592Ed02b382C7d682851Ff241c016c4550E11E5a",
  },
  {
    label: "USDT (BEP20)",
    value: "0x19037ab0c4529CD90F4f7e408AbCDcF72338481b",
  },
  {
    label: "BUSD",
    value: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  },
  {
    label: "OPY",
    value: "0x7a656f418afc09eaF4AE8b75EAe74fE09E7A7706",
  },
  {
    label: "FTY",
    value: "0x500f8c7a9eE51C5b6f6b8D836d84340d7272D84E",
  },
  {
    label: "MET$",
    value: "0xDd770074aa548B66Ab54CF0f6E67F9354E3b732e",
  },
  {
    label: "SOLANA",
    value: "SOLANA",
  },
  {
    label: "SoLpy",
    value: "SoLpy",
  },
];

export const countryCodes = [
  { label: "ANDORRA", value: "+376" },
  { label: "UNITED ARAB EMIRATES", value: "+971" },
  { label: "AFGHANISTAN", value: "+93" },
  { label: "ANTIGUA AND BARBUDA", value: "+1268" },
  { label: "ANGUILLA", value: "+1264" },
  { label: "ALBANIA", value: "+355" },
  { label: "ARMENIA", value: "+374" },
  { label: "NETHERLANDS ANTILLES", value: "+599" },
  { label: "ANGOLA", value: "+244" },
  { label: "ANTARCTICA", value: "+672" },
  { label: "ARGENTINA", value: "+54" },
  { label: "AMERICAN SAMOA", value: "+1684" },
  { label: "AUSTRIA", value: "+43" },
  { label: "AUSTRALIA", value: "+61" },
  { label: "ARUBA", value: "+297" },
  { label: "AZERBAIJAN", value: "+994" },
  { label: "BOSNIA AND HERZEGOVINA", value: "+387" },
  { label: "BARBADOS", value: "+1246" },
  { label: "BANGLADESH", value: "+880" },
  { label: "BELGIUM", value: "+32" },
  { label: "BURKINA FASO", value: "+226" },
  { label: "BULGARIA", value: "+359" },
  { label: "BAHRAIN", value: "+973" },
  { label: "BURUNDI", value: "+257" },
  { label: "BENIN", value: "+229" },
  { label: "SAINT BARTHELEMY", value: "+590" },
  { label: "BERMUDA", value: "+1441" },
  { label: "BRUNEI DARUSSALAM", value: "+673" },
  { label: "BOLIVIA", value: "+591" },
  { label: "BRAZIL", value: "+55" },
  { label: "BAHAMAS", value: "+1242" },
  { label: "BHUTAN", value: "+975" },
  { label: "BOTSWANA", value: "+267" },
  { label: "BELARUS", value: "+375" },
  { label: "BELIZE", value: "+501" },
  { label: "CANADA", value: "+1" },
  { label: "COCOS (KEELING) ISLANDS", value: "+61" },
  { label: "CONGO, THE DEMOCRATIC REPUBLIC OF THE", value: "+243" },
  { label: "CENTRAL AFRICAN REPUBLIC", value: "+236" },
  { label: "CONGO", value: "+242" },
  { label: "SWITZERLAND", value: "+41" },
  { label: "COTE D IVOIRE", value: "+225" },
  { label: "COOK ISLANDS", value: "+682" },
  { label: "CHILE", value: "+56" },
  { label: "CAMEROON", value: "+237" },
  { label: "CHINA", value: "+86" },
  { label: "COLOMBIA", value: "+57" },
  { label: "COSTA RICA", value: "+506" },
  { label: "CUBA", value: "+53" },
  { label: "CAPE VERDE", value: "+238" },
  { label: "CHRISTMAS ISLAND", value: "+61" },
  { label: "CYPRUS", value: "+357" },
  { label: "CZECH REPUBLIC", value: "+420" },
  { label: "GERMANY", value: "+49" },
  { label: "DJIBOUTI", value: "+253" },
  { label: "DENMARK", value: "+45" },
  { label: "DOMINICA", value: "+1767" },
  { label: "DOMINICAN REPUBLIC", value: "+1809" },
  { label: "ALGERIA", value: "+213" },
  { label: "ECUADOR", value: "+593" },
  { label: "ESTONIA", value: "+372" },
  { label: "EGYPT", value: "+20" },
  { label: "ERITREA", value: "+291" },
  { label: "SPAIN", value: "+34" },
  { label: "ETHIOPIA", value: "+251" },
  { label: "FINLAND", value: "+358" },
  { label: "FIJI", value: "+679" },
  { label: "FALKLAND ISLANDS (MALVINAS)", value: "+500" },
  { label: "MICRONESIA, FEDERATED STATES OF", value: "+691" },
  { label: "FAROE ISLANDS", value: "+298" },
  { label: "FRANCE", value: "+33" },
  { label: "GABON", value: "+241" },
  { label: "UNITED KINGDOM", value: "+44" },
  { label: "GRENADA", value: "+1473" },
  { label: "GEORGIA", value: "+995" },
  { label: "GHANA", value: "+233" },
  { label: "GIBRALTAR", value: "+350" },
  { label: "GREENLAND", value: "+299" },
  { label: "GAMBIA", value: "+220" },
  { label: "GUINEA", value: "+224" },
  { label: "EQUATORIAL GUINEA", value: "+240" },
  { label: "GREECE", value: "+30" },
  { label: "GUATEMALA", value: "+502" },
  { label: "GUAM", value: "+1671" },
  { label: "GUINEA-BISSAU", value: "+245" },
  { label: "GUYANA", value: "+592" },
  { label: "HONG KONG", value: "+852" },
  { label: "HONDURAS", value: "+504" },
  { label: "CROATIA", value: "+385" },
  { label: "HAITI", value: "+509" },
  { label: "HUNGARY", value: "+36" },
  { label: "INDONESIA", value: "+62" },
  { label: "IRELAND", value: "+353" },
  { label: "ISRAEL", value: "+972" },
  { label: "ISLE OF MAN", value: "+44" },
  { label: "INDIA", value: "+91" },
  { label: "IRAQ", value: "+964" },
  { label: "IRAN, ISLAMIC REPUBLIC OF", value: "+98" },
  { label: "ICELAND", value: "+354" },
  { label: "ITALY", value: "+39" },
  { label: "JAMAICA", value: "+1876" },
  { label: "JORDAN", value: "+962" },
  { label: "JAPAN", value: "+81" },
  { label: "KENYA", value: "+254" },
  { label: "KYRGYZSTAN", value: "+996" },
  { label: "CAMBODIA", value: "+855" },
  { label: "KIRIBATI", value: "+686" },
  { label: "COMOROS", value: "+269" },
  { label: "SAINT KITTS AND NEVIS", value: "+1869" },
  { label: "KOREA DEMOCRATIC PEOPLES REPUBLIC OF", value: "+850" },
  { label: "KOREA REPUBLIC OF", value: "+82" },
  { label: "KUWAIT", value: "+965" },
  { label: "CAYMAN ISLANDS", value: "+1345" },
  { label: "KAZAKSTAN", value: "+7" },
  { label: "LAO PEOPLES DEMOCRATIC REPUBLIC", value: "+856" },
  { label: "LEBANON", value: "+961" },
  { label: "SAINT LUCIA", value: "+1758" },
  { label: "LIECHTENSTEIN", value: "+423" },
  { label: "SRI LANKA", value: "+94" },
  { label: "LIBERIA", value: "+231" },
  { label: "LESOTHO", value: "+266" },
  { label: "LITHUANIA", value: "+370" },
  { label: "LUXEMBOURG", value: "+352" },
  { label: "LATVIA", value: "+371" },
  { label: "LIBYAN ARAB JAMAHIRIYA", value: "+218" },
  { label: "MOROCCO", value: "+212" },
  { label: "MONACO", value: "+377" },
  { label: "MOLDOVA, REPUBLIC OF", value: "+373" },
  { label: "MONTENEGRO", value: "+382" },
  { label: "SAINT MARTIN", value: "+1599" },
  { label: "MADAGASCAR", value: "+261" },
  { label: "MARSHALL ISLANDS", value: "+692" },
  { label: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF", value: "+389" },
  { label: "MALI", value: "+223" },
  { label: "MYANMAR", value: "+95" },
  { label: "MONGOLIA", value: "+976" },
  { label: "MACAU", value: "+853" },
  { label: "NORTHERN MARIANA ISLANDS", value: "+1670" },
  { label: "MAURITANIA", value: "+222" },
  { label: "MONTSERRAT", value: "+1664" },
  { label: "MALTA", value: "+356" },
  { label: "MAURITIUS", value: "+230" },
  { label: "MALDIVES", value: "+960" },
  { label: "MALAWI", value: "+265" },
  { label: "MEXICO", value: "+52" },
  { label: "MALAYSIA", value: "+60" },
  { label: "MOZAMBIQUE", value: "+258" },
  { label: "NAMIBIA", value: "+264" },
  { label: "NEW CALEDONIA", value: "+687" },
  { label: "NIGER", value: "+227" },
  { label: "NIGERIA", value: "+234" },
  { label: "NICARAGUA", value: "+505" },
  { label: "NETHERLANDS", value: "+31" },
  { label: "NORWAY", value: "+47" },
  { label: "NEPAL", value: "+977" },
  { label: "NAURU", value: "+674" },
  { label: "NIUE", value: "+683" },
  { label: "NEW ZEALAND", value: "+64" },
  { label: "OMAN", value: "+968" },
  { label: "PANAMA", value: "+507" },
  { label: "PERU", value: "+51" },
  { label: "FRENCH POLYNESIA", value: "+689" },
  { label: "PAPUA NEW GUINEA", value: "+675" },
  { label: "PHILIPPINES", value: "+63" },
  { label: "PAKISTAN", value: "+92" },
  { label: "POLAND", value: "+48" },
  { label: "SAINT PIERRE AND MIQUELON", value: "+508" },
  { label: "PITCAIRN", value: "+870" },
  { label: "PUERTO RICO", value: "+1" },
  { label: "PORTUGAL", value: "+351" },
  { label: "PALAU", value: "+680" },
  { label: "PARAGUAY", value: "+595" },
  { label: "QATAR", value: "+974" },
  { label: "ROMANIA", value: "+40" },
  { label: "SERBIA", value: "+381" },
  { label: "RUSSIAN FEDERATION", value: "+7" },
  { label: "RWANDA", value: "+250" },
  { label: "SAUDI ARABIA", value: "+966" },
  { label: "SOLOMON ISLANDS", value: "+677" },
  { label: "SEYCHELLES", value: "+248" },
  { label: "SUDAN", value: "+249" },
  { label: "SWEDEN", value: "+46" },
  { label: "SINGAPORE", value: "+65" },
  { label: "SAINT HELENA", value: "+290" },
  { label: "SLOVENIA", value: "+386" },
  { label: "SLOVAKIA", value: "+421" },
  { label: "SIERRA LEONE", value: "+232" },
  { label: "SAN MARINO", value: "+378" },
  { label: "SENEGAL", value: "+221" },
  { label: "SOMALIA", value: "+252" },
  { label: "SURINAME", value: "+597" },
  { label: "SAO TOME AND PRINCIPE", value: "+239" },
  { label: "EL SALVADOR", value: "+503" },
  { label: "SYRIAN ARAB REPUBLIC", value: "+963" },
  { label: "SWAZILAND", value: "+268" },
  { label: "TURKS AND CAICOS ISLANDS", value: "+1649" },
  { label: "CHAD", value: "+235" },
  { label: "TOGO", value: "+228" },
  { label: "THAILAND", value: "+66" },
  { label: "TAJIKISTAN", value: "+992" },
  { label: "TOKELAU", value: "+690" },
  { label: "TIMOR-LESTE", value: "+670" },
  { label: "TURKMENISTAN", value: "+993" },
  { label: "TUNISIA", value: "+216" },
  { label: "TONGA", value: "+676" },
  { label: "TURKEY", value: "+90" },
  { label: "TRINIDAD AND TOBAGO", value: "+1868" },
  { label: "TUVALU", value: "+688" },
  { label: "TAIWAN, PROVINCE OF CHINA", value: "+886" },
  { label: "TANZANIA, UNITED REPUBLIC OF", value: "+255" },
  { label: "UKRAINE", value: "+380" },
  { label: "UGANDA", value: "+256" },
  { label: "UNITED STATES", value: "+1" },
  { label: "URUGUAY", value: "+598" },
  { label: "UZBEKISTAN", value: "+998" },
  { label: "HOLY SEE (VATICAN CITY STATE)", value: "+39" },
  { label: "SAINT VINCENT AND THE GRENADINES", value: "+1784" },
  { label: "VENEZUELA", value: "+58" },
  { label: "VIRGIN ISLANDS, BRITISH", value: "+1284" },
  { label: "VIRGIN ISLANDS, U.S.", value: "+1340" },
  { label: "VIET NAM", value: "+84" },
  { label: "VANUATU", value: "+678" },
  { label: "WALLIS AND FUTUNA", value: "+681" },
  { label: "SAMOA", value: "+685" },
  { label: "KOSOVO", value: "+381" },
  { label: "YEMEN", value: "+967" },
  { label: "MAYOTTE", value: "+262" },
  { label: "SOUTH AFRICA", value: "+27" },
  { label: "ZAMBIA", value: "+260" },
  { label: "ZIMBABWE", value: "+263" },
];
export const countries = [
  { label: "Afghanistan", value: "AF" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas (the)", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia (Plurinational State of)", value: "BO" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory (the)", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cabo Verde", value: "CV" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cayman Islands (the)", value: "KY" },
  { label: "Central African Republic (the)", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands (the)", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros (the)", value: "KM" },
  { label: "Congo (the Democratic Republic of the)", value: "CD" },
  { label: "Congo (the)", value: "CG" },
  { label: "Cook Islands (the)", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Curaçao", value: "CW" },
  { label: "Cyprus", value: "CY" },
  { label: "Czechia", value: "CZ" },
  { label: "Côte d'Ivoire", value: "CI" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic (the)", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Eswatini", value: "SZ" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (the) [Malvinas]", value: "FK" },
  { label: "Faroe Islands (the)", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories (the)", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia (the)", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and McDonald Islands", value: "HM" },
  { label: "Holy See (the)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran (Islamic Republic of)", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea (the Democratic People's Republic of)", value: "KP" },
  { label: "Korea (the Republic of)", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People's Democratic Republic (the)", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands (the)", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia (Federated States of)", value: "FM" },
  { label: "Moldova (the Republic of)", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands (the)", value: "NL" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger (the)", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands (the)", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestine, State of", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines (the)", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Republic of North Macedonia", value: "MK" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation (the)", value: "RU" },
  { label: "Rwanda", value: "RW" },
  { label: "Réunion", value: "RE" },
  { label: "Saint Barthélemy", value: "BL" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Martin (French part)", value: "MF" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Sint Maarten (Dutch part)", value: "SX" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "South Sudan", value: "SS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan (the)", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands (the)", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates (the)", value: "AE" },
  {
    label: "United Kingdom of Great Britain and Northern Ireland (the)",
    value: "GB",
  },
  { label: "United States Minor Outlying Islands (the)", value: "UM" },
  { label: "United States of America (the)", value: "US" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela (Bolivarian Republic of)", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands (British)", value: "VG" },
  { label: "Virgin Islands (U.S.)", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
  { label: "Åland Islands", value: "AX" },
];
export const optionColors1 = [
  { value: "#BDC3C7", label: "Light Gray" },
  { value: "#C0392B", label: "Red" },
  { value: "#E74C3C", label: "Light Red" },
  { value: "#9B59B6", label: "Light Purple" },
  { value: "#8E44AD", label: "Purple" },
  { value: "#2980B9", label: "Dark Blue" },
  { value: "#3498DB", label: "Light Blue" },
  { value: "#1ABC9C", label: "Sea Green" },
  { value: "#16A085", label: "Dark Sea Green" },
  { value: "#27AE60", label: "Dark Green" },
  { value: "#2ECC71", label: "Light Green" },
  { value: "#F1C40F", label: "Yellow" },
  { value: "#E67E22", label: "Dark Orange" },
  { value: "#D35400", label: "Dull Orange" },
];
export const optionColors2 = [
  { value: "#1ABC9C", label: "Sea Green" },
  { value: "#F1C40F", label: "Yellow" },
  { value: "#16A085", label: "Dark Sea Green" },
  { value: "#27AE60", label: "Dark Green" },
  { value: "#2ECC71", label: "Light Green" },
  { value: "#E67E22", label: "Dark Orange" },
  { value: "#D35400", label: "Dull Orange" },
];
export const optionColors3 = [
  { value: "#D35400", label: "Dull Orange" },
  { value: "#F1C40F", label: "Yellow" },
  { value: "#27AE60", label: "Dark Green" },
  { value: "#D35400", label: "Dull Orange" },
  { value: "#2ECC71", label: "Light Green" },
  { value: "#F1C40F", label: "Yellow" },
  { value: "#2980B9", label: "Dark Blue" },
  { value: "#3498DB", label: "Light Blue" },
];
export const optionColors4 = [
  { value: "#1ABC9C", label: "Sea Green" },
  { value: "#2980B9", label: "Dark Blue" },
  { value: "#9B59B6", label: "Light Purple" },
  { value: "#E74C3C", label: "Light Red" },
  { value: "#16A085", label: "Dark Sea Green" },
  { value: "#2ECC71", label: "Light Green" },
  { value: "#F1C40F", label: "Yellow" },
  { value: "#E67E22", label: "Dark Orange" },
];
export const chainIdToName = {
  137: "Polygon ",
  1: "Ethereum ",
  97: "BSC",
  56: "BSC",
  80001: "Polygon ",
  11155111: "Ethereum ",
  123: "SOLANA",
  321: "Bitcoin",
  111: "TRON",
};
export const getStatusBadge = (status) => {
  switch (status) {
    case "pending":
      return (
        <div className="mt-1 text-center bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2 py-2 rounded">
          Pending
        </div>
      );
    case "pending_withdraw":
      return (
        <div className="mt-1 text-center bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2 py-2 rounded">
          pending_withdraw
        </div>
      );
    case "in-progress":
      return (
        <div className="mt-1 text-center text-nowrap bg-primary text-white text-sm font-medium mr-2 px-2 py-2 rounded">
          In-Progress
        </div>
      );
    case "waiting_confirmation":
      return (
        <div className="mt-1 text-center text-nowrap bg-primary text-white text-sm font-medium mr-2 px-2 py-2 rounded">
          waiting_confirmation
        </div>
      );
    case "waiting":
      return (
        <div className="mt-1 text-center text-nowrap bg-primary text-white text-sm font-medium mr-2 px-2 py-2 rounded">
          waiting
        </div>
      );
    case "Waiting":
      return (
        <div className="mt-1 text-center text-nowrap bg-warning text-white text-sm font-medium mr-2 px-2 py-2 rounded">
          Waiting
        </div>
      );
    case "approved":
      return (
        <div className="mt-1 text-center bg-green-100 text-green-800 text-sm font-medium mr-2 px-1 py-2 rounded">
          Approved
        </div>
      );
    case "active":
      return (
        <div className="mt-1 bg-green-100 text-green-800 w-[100%] md:w-[40%] text-center text-sm font-medium  px-1 py-2 rounded">
          Active
        </div>
      );
    case "Completed":
      return (
        <div className="mt-1 bg-green-100 text-green-800 w-[100%] md:w-[100%] text-center text-sm font-medium  px-1 py-2 rounded">
          Completed
        </div>
      );
    case "rejected":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Rejected
        </div>
      );
    case "Rejected":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Rejected
        </div>
      );
    case "Declined":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Declined
        </div>
      );
    case "Failed":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Failed
        </div>
      );
    case "error":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Error
        </div>
      );
    case "failed":
      return (
        <div className="mt-1 text-center bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
          Failed
        </div>
      );
    case "deactivated":
      return (
        <div className="mt-1 text-center bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-1 py-2 rounded">
          Deactivated
        </div>
      );
    default:
      return <div className="badge">{status || "Unknown"}</div>;
  }
};
export const optionSymbolStriga = [
  { value: "All", label: "All" },
  { value: "EUR", label: "EUR" },
  { value: "USDC", label: "USDC" },
  { value: "BTC", label: "BTC" },
  { value: "BNB", label: "BNB" },
  { value: "ETH", label: "ETH" },
  { value: "USDT", label: "USDT" },
];
export const optionSymbol = [
  { value: "ALL", label: "ALL" },
  { value: "USDC", label: "USDC" },
  { value: "USDT", label: "USDT" },
  { value: "PAX", label: "PAX" },
  { value: "BNB", label: "BNB" },
  { value: "CTY", label: "CTY" },
  { value: "GBPT", label: "GBPT" },
  { value: "JPYC", label: "JPYC" },
  { value: "EUROC", label: "EUROC" },
  { value: "BUSD", label: "BUSD" },
  { value: "OPY", label: "OPY" },
  { value: "FTY", label: "FTY" },
  { value: "MET$", label: "MET$" },
];
