import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  CardBody, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import { colorAccent, colorBackground } from '@/utils/palette';
import {
  left, right, paddingRight, paddingLeft, borderLeft, borderRight, marginLeft,
} from '@/utils/directions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from '../../../shared/components/Button';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const CertificateTimeLineItem = ({
  type, img, title, date, email, status, description, certificates, documentLink, hash, batchNumber,
  origin,
}) => {

  const [showFullDescription, setShowFullDescription] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCYx6E7TSAHDQMFB41DNksmX7v3zXaKt5Y', // Replace with your API key
    libraries: ['places'],
  });

  const [showMap, setShowMap] = useState(false);
  const [coordinates, setCoordinates] = useState(null);

  const toggleMap = () => {
    if (!showMap && origin) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(origin)}&key=AIzaSyCYx6E7TSAHDQMFB41DNksmX7v3zXaKt5Y`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.results && data.results[0]) {
            const location = data.results[0].geometry.location;
            setCoordinates({ lat: location.lat, lng: location.lng });
          } else {
            console.error('Unable to geocode address');
          }
        })
        .catch((error) => console.error('Geocoding error:', error));
    }
    setShowMap((prev) => !prev);
  };

  const toggleDescription = () => {
    setShowFullDescription((prev) => !prev);
  };

  return (
    <TimelineItemWrap>
      {img ? (
        <TimeLineIcon status={'active'}><img src={img} alt="img" /></TimeLineIcon>
      ) : (
        <TimeLineIcon type={'work'}><span className="lnr lnr-file-empty" /></TimeLineIcon>
      )}
      <TimeLineItemContent>
        <TypeLabel>{type}</TypeLabel> {/* Add this line */}

        <TimeLineItemTitle>{title}</TimeLineItemTitle>
        <CardSubhead>{date}</CardSubhead>

        {batchNumber && <DetailField><strong>Batch Number:</strong> {batchNumber}</DetailField>}
        {origin && (
          <DetailField>
            <strong>Origin:</strong> {origin}
            <SeeMoreButton onClick={toggleMap}>
              {showMap ? 'Hide Map' : 'Show Map'}
            </SeeMoreButton>
            {showMap && isLoaded && coordinates && (
              <div style={{ marginTop: '10px', height: '300px', width: '100%' }}>
                <GoogleMap
                  mapContainerStyle={{ height: '100%', width: '100%' }}
                  center={coordinates}
                  zoom={11}
                >
                  <Marker position={coordinates} />
                </GoogleMap>
              </div>
            )}
          </DetailField>
        )}
        {email && <DetailField><strong>Email:</strong> {email}</DetailField>}
        {hash && (
          <DetailField>
            <strong>Hash:</strong>
            <HashLink
              role="link"
              onClick={() => window.open(`https://bscscan.com/tx/${hash}`, '_blank', 'noopener noreferrer')}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </HashLink>
          </DetailField>
        )}

        {description && <Description>
          {showFullDescription ? description : `${description.substring(0, 150)}...`}
          {description.length > 100 && (
            <SeeMoreButton onClick={toggleDescription}>
              {showFullDescription ? 'See Less' : 'See More'}
            </SeeMoreButton>
          )}
        </Description>}

        {documentLink && (
          <div>
            {documentLink.map((item, idx) => (
              <Button key={idx} variant='outline-primary' href={item} target="_blank" rel="noopener noreferrer">
                Doc {idx + 1}
              </Button>
            ))}
          </div>
        )}


      </TimeLineItemContent>
    </TimelineItemWrap>
  );
};



export default CertificateTimeLineItem;

// region STYLES

const TimeLineItemContent = styled(CardBody)`
  width: calc(50% - 52px);
  ${paddingRight}: 25px;
  ${paddingLeft}: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 5px;
  position: relative;
  background-color: ${colorBackground};

  &:after {
    content: '';
    position: absolute;
    ${right}: -20px;
    top: 20px;
    border: 10px solid transparent;
    ${borderLeft}: 10px solid ${colorBackground};
  }
`;

const TimeLineItemTitle = styled(CardTitle)`
  text-transform: uppercase;
  font-weight: 700;
`;

const Description = styled.p`
  margin: 15px 0;
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-wrap;
`;

const SeeMoreButton = styled.button`
  background: none;
  border: none;
  color: ${colorAccent};
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${colorAccent};
  margin: 20px 0;
`;

const CertificateItem = styled.div`
  margin-bottom: 15px;
`;

const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;

const DetailField = styled.p`
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.4;
  color: white;
`;

const TimelineItemWrap = styled.div`
  position: relative;
  padding-bottom: 50px;
  text-align: ${left};

  &:nth-child(even) {

    ${TimeLineItemContent} {
      ${marginLeft}: calc(50% + 52px);

      &:after {
        ${right}: auto;
        ${left}: -20px;
        border: 10px solid transparent;
        ${borderRight}: 10px solid ${colorBackground};
      }
    }
  }

  @media screen and (max-width: 991px) {

    &:nth-child(even) ${TimeLineItemContent},
    & ${TimeLineItemContent} {
      ${marginLeft}: 72px;
      width: calc(100% - 72px);

      &:after {
        ${right}: auto;
        ${left}: -20px;
        border: 10px solid transparent;
        ${borderRight}: 10px solid ${colorBackground};
      }
    }
  }
`;

const TimeLineIcon = styled.div`
  position: absolute;
  ${left}: calc(50% - 25px);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-image: ${props => getIconColor(props.type)};
  overflow: hidden;
  text-align: center;
  display: flex;

  img {
    height: 100%;
    width: auto;
    min-width: 100%;
    filter: ${props => (props.status === "active" ? "none" : "grayscale(100%)")};
  }

  span {
    color: white;
    font-size: 20px;
    margin: auto;
  }

  @media screen and (max-width: 991px) {
    ${left}: 0;
  }
`;
const TypeLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${colorAccent};
  margin-bottom: 10px;
`;

const getIconColor = (type) => {
  switch (type) {
    case 'work':
      return 'linear-gradient(to bottom, #4ce1b6, #1cd25a), linear-gradient(#4ce1b6, #4ce1b6)';
    case 'video':
      return 'linear-gradient(to bottom, #708eee, #9e44f3), linear-gradient(#7082ee, #7082ee)';
    case 'file':
      return 'linear-gradient(to bottom, #ee4f99, #f76e5b)';
    default:
      return colorAccent;
  }
};

// endregion
