import Modal from 'react-bootstrap/Modal';

const GetAccessModal = ({show,hide,data}) => {

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hide}
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-center" style={{ fontSize: 'large' }}><b>TFA Disabled </b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container padding-bottom-3x p-0">
                    <div className="card mt-0 p-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-12 text-success text-center">
                                    <i className="fa fa-check-square-o mt-1" style={{ fontSize: '35px' }} aria-hidden="true" />
                                </div>
                                <div className="col-12 text-dark text-center">
                                    <p className="text-dark fs-5">
                                        TFA Authentication is successfully disabled
                                    </p>
                                    <p className="text-dark fs-5">
                                        You can login without TFA
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default GetAccessModal