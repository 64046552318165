/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import { toast } from "react-toastify";
import AuthService from "../../utils/services/auth.services";

export const AUTHENTICATE = "AUTHENTICATE";
export const AUTHENTICATE_ERROR_AUTH = "AUTHENTICATE_ERROR_AUTH";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const VERIFY_OTP = "VERIFY_OTP";
export const RESEND_OTP = "RESEND_OTP";
export const CLEAR_REGISTER = "CLEAR_REGISTER";
export const FORGOT_PASS = "FORGOT_PASS";
export const SEND_TOKEN = "SEND_TOKEN";
export const SET_STATUS = "SET_STATUS";
export const TWO_FACTOR = "TWO_FACTOR";
export const CLEAR_FACTOR = "CLEAR_FACTOR";
export const TOTAL_BALANCE = "TOTAL_BALANCE";
export const COIN_LOAD = "COIN_LOAD";
export const SET_COIN_LOAD = "SET_COIN_LOAD";
export const COIN_LOADS = "COIN_LOADS";
export const SET_COIN_LOADS = "SET_COIN_LOADS";
export const SET_WALLET_BALANCE = "SET_WALLET_BALANCE";
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";

export function walletBalance({ payload }) {
  // console.log('called');
  return {
    type: SET_WALLET_BALANCE,
    balance: payload,
  };
}

export function auth({ name, avatar }) {
  return {
    type: AUTHENTICATE,
    user: { name, avatar },
  };
}

export function authError(error) {
  return {
    type: AUTHENTICATE_ERROR_AUTH,
    error,
  };
}
export const register = (data) => (dispatch) =>
  AuthService.register(data).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      toast.error(error?.response?.data?.message || "something went wrong");
      // console.log("🚀 ~ error:", error.respose.data.message)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 500) {
        dispatch(resendOtp(data?.email));
      }
      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
export const verifyOtp = (otp, email) => (dispatch) =>
  AuthService.verifyOtp(otp, email).then(
    (response) => {
      dispatch({
        type: VERIFY_OTP,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      // return Promise.reject();
    }
  );
export const resendOtp = (email) => (dispatch) =>
  AuthService.resendOtp(email).then(
    (response) => {
      dispatch({
        type: RESEND_OTP,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
export const forgotPasword = (email) => (dispatch) =>
  AuthService.forgetPass(email).then(
    (response) => {
      dispatch({
        type: FORGOT_PASS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FORGOT_PASS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
export const login = (username, password) => (dispatch) =>
  AuthService.login(username, password).then(
    (data) => {
      if (data.status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
        dispatch({
          type: CLEAR_MESSAGE,
        });
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      dispatch({
        type: TWO_FACTOR,
        payload: error.response.data.status,
      });

      return Promise.reject();
    }
  );

export const adminSwitchProfile = (email) => (dispatch) =>
  AuthService.adminSwitchProfile(email).then(
    (data) => {
      if (data.status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
        dispatch({
          type: CLEAR_MESSAGE,
        });
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      dispatch({
        type: TWO_FACTOR,
        payload: error.response.data.status,
      });

      return Promise.reject();
    }
  );

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};
export const clearMsg = () => (dispatch) => {
  dispatch({
    type: CLEAR_MESSAGE,
  });
  dispatch({
    type: CLEAR_REGISTER,
  });
};

export const clearSendMsg = () => (dispatch) => {
  dispatch({
    type: CLEAR_MESSAGE,
  });
};

export const changePassword = (password, newPassword) => (dispatch) =>
  AuthService.changePassword(password, newPassword).then(
    (response) => {
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      dispatch({
        type: SET_STATUS,
        payload: response.status,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      dispatch({
        type: SET_STATUS,
        payload: error.response.status,
      });
      return Promise.reject();
    }
  );
export const varifyPasswordotp = (email, otp) => (dispatch) =>
  AuthService.varifyPasswordotp(email, otp).then(
    (response) => {
      dispatch({
        type: FORGOT_PASS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FORGOT_PASS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
export const resetPassword =
  (email, otp, password, confirmPassword) => (dispatch) =>
    AuthService.resetPassword(email, otp, password, confirmPassword).then(
      (response) => {
        dispatch({
          type: FORGOT_PASS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: FORGOT_PASS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
export const alertMsg = (message) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: message,
  });
};
export const verifyTwoFactor = (otp, email) => (dispatch) =>
  AuthService.verifyTwoFactor(otp, email).then(
    (data) => {
      // console.log('satus', data);
      if (data.status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
        dispatch({
          type: CLEAR_MESSAGE,
        });
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      dispatch({
        type: SET_STATUS,
        payload: error.response.data.status,
      });

      return Promise.reject();
    }
  );
export const clearTwoF = () => (dispatch) => {
  dispatch({
    type: CLEAR_FACTOR,
  });
};
