import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../shared/components/Button";

const StoreCardGrid = ({ stores }) => {

  return (
    <div className="row px-4">
      {stores.length > 0 ? (
        stores.map((store) => (
          <div
            className="col-lg-3 col-md-6 mb-4 col-sm-12"
            style={{ textTransform: "capitalize" }}
            key={store._id}
          >
            <div
              className=" justify-content-between"
              style={{ background: "#232329", color: "white", borderRadius: "8px" }}
            >
              <Link to={`/store-details-by-id?id=${encodeURIComponent(store._id)}`}>
                <div className="mt-4 text-center">
                  <img
                    src={store.storeLogo}
                    alt={store.storeName}
                    className="col-12 rounded-4 p-2  py-2 zoom-in-on-hover"
                  // style={{
                  //   maxHeight: "150px",
                  //   maxWidth: "100%",
                  //   objectFit: "contain",
                  //   borderRadius: "50%",
                  //   backgroundColor: "#fff",
                  //   padding: "10px",
                  // }}
                  />
                </div>
              </Link>

              <hr />

              <div className="py-3 px-3">
                <h5 className="card-title">{store.storeName}</h5>
                <p className="mb-1">Type: {store.store_type}</p>
                <p
                  className="mb-1"
                  style={{
                    color: store.status === "approved" ? "#28a745" : "#dc3545",
                    fontWeight: "bold",
                  }}
                >
                  Status: {store.status}
                </p>
              </div>

              {/* <div className="px-3 mb-3 d-flex justify-content-between">
                <Link
                  to={`/store-edit/${store._id}`}
                  className="btn btn-sm btn-primary"
                >
                  Edit
                </Link>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => console.log("Delete store:", store._id)}
                >
                  Delete
                </button>
              </div> */}
            </div>
          </div>
        ))
      ) : (
        <div className="row text-center">
        <div className="col-lg-12 col-md-12 col-sm-12 align-items-center  py-1 justify-content-center">
            <p className="py-4">No Store available.</p>
            <Link to="/createstore" className="my-2 w-100 shadow-none text-capitalize">
              <Button variant={'outline-primary'}>

                Create Store
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreCardGrid;
