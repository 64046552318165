/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import { Ring } from '@uiball/loaders';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye, faEnvelope, faUser, faFile, faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getMyStore,
    editStoreLogo,
    pausePlaySelling,
    getStoreInfoById,
} from '../../../utils/services/user.service';
import Product from '../product/Product';
import { Button } from '../../../shared/components/Button';
import '../style/storedetail.css';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import StoreCardGrid from './StoreCardGrid';

const StoreDetailById = () => {
    const [storeId, setstoreId] = useState([]); // Get the store ID from URL parameter
    const [storeData, setStoreData] = useState(null);
    const [A, AA] = useState([]);
    const [B, BB] = useState([]);
    const [A1, AAA] = useState([]);
    const [B1, BBB] = useState([]);
    const [A2, AAAA] = useState([]);
    const [B2, BBBB] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setloading] = useState(false);
    const [storeSellingStaus, setStoreSellingStatus] = useState(true);
    const [refreshProducts, setRefreshProducts] = useState(false);
    const [showFullAddress, setShowFullAddress] = useState(false);
    const [getStoreName, setStoreName] = useState('');

    const location = useLocation()
    // Extract the `id` from the query string
    const queryParams = new URLSearchParams(location.search);
    const storeUrlId = queryParams.get("id");

    const handleViewMoreClick = () => {
        setShowFullAddress(!showFullAddress);
    };
    const [showFullAddress1, setShowFullAddress1] = useState(false);
    const handleViewMoreClick1 = () => {
        setShowFullAddress1(!showFullAddress1);
    };
    const [showFullAddress2, setShowFullAddress2] = useState(false);
    const handleViewMoreClick2 = () => {
        setShowFullAddress2(!showFullAddress2);
    };
    const [storeData2, setStoreData2] = useState(null);
    const [storeData2Id, setStoreData2Id] = useState(null);

    useEffect(() => {

        const fetchStoreById = async () => {
            try {
                setIsLoading(true)
                const response = await getStoreInfoById({ store_id: storeUrlId }); // Replace with your API call
                if (response.data) {
                    setloading(false);
                    setStoreData(response.data); // Set the detailed store data
                    AA(response.data.result[0]?.storeAddress);
                    setStoreSellingStatus(response.data.result[0]?.selling_status);
                    BB(showFullAddress ? response.data.result[0]?.storeAddress : response.data.result[0]?.storeAddress.slice(0, 10));
                    AAA(response.data.result[0]?.approver_reason);
                    BBB(showFullAddress1 ? response.data.result[0]?.approver_reason : response.data.result[0]?.approver_reason.slice(0, 10));
                    AAAA(response.data.result[0]?.rejection_reason);
                    BBBB(showFullAddress2 ? response.data.result[0]?.rejection_reason : response.data.result[0]?.rejection_reason.slice(0, 10));
                    const address = storeData.result[0]?.rejection_reason;
                    const truncatedAddress = showFullAddress ? address : address.slice(0, 10);
                    setStoreName(storeData.result[0]?.storeName);
                    setIsLoading(false)

                } else {
                    setloading(false);
                    setIsLoading(false)
                    console.error('Error fetching store details:', response.statusText);
                }
            } catch (error) {
                setloading(false);
                setIsLoading(false)
                console.error('Error fetching store details:', error);
            }
        };

        if (storeUrlId) {
            fetchStoreById();
        }
    }, [storeSellingStaus, refreshProducts, storeUrlId]);

    const handleLogoChange = async (event) => {
        const formData = new FormData();
        const logoFile = event.target.files[0];
        formData.append('File', logoFile);
        formData.append('storeId', storeUrlId);

        try {
            const response = await editStoreLogo(formData);
            if (response.status === 200) {
                // Show a success toast message


                // Refresh the page after a short delay (e.g., 2 seconds)
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                toast.success('Store logo updated successfully', {
                    autoClose: 2000, // Close the toast after 2 seconds
                });
            }
        } catch (error) {
            console.error('Error updating store logo:', error);
        }
    };

    const handlePausePlay = async () => {
        setIsLoading(true)
        const payload = {
            store_id: storeUrlId,
            new_status: storeSellingStaus == true ? false : true
        }
        const res = await pausePlaySelling(payload)
        toast.success(res?.data?.Message)
        setIsLoading(false)
        setRefreshProducts(!refreshProducts)
    }
    const handleRefreshProducts = () => {
        // Toggle the state to trigger re-rendering of the Product component
        setRefreshProducts(prevState => !prevState);
    };
    // console.log('A', A);
    // console.log('B', B);

    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? 'flex' : 'none', // Use flex display when loading
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <div className="container py-3">
                <h2 hidden className="text-center mb-4">Welcome to Your Store</h2>
                {storeData ? (
                    <div className="row px-4 mt-2">
                        <div
                            className="col-lg-4 order-1 order-lg-1 hero-img py-1 justify-content-center"
                            data-aos="fade-right"
                            data-aos-delay="200"
                        >
                            <div className="square-img-wrapper">
                                <img src={storeData?.result[0]?.storeLogo} className="card-img-top square-img" alt="storelogo" />
                            </div>
                            <div className="overlay-text-new px-2">
                                <label className="change-logo-icon cam" htmlFor="logoInput">
                                    <FontAwesomeIcon icon={faCamera} style={{ color: '#ffffff' }} />
                                    <input
                                        id="logoInput"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleLogoChange}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-8 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center py-1 px-3">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <h3 style={{ textTransform: 'Capitalize' }} id="h2" data-aos="fade-up">{storeData?.result[0]?.storeName}</h3>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12">

                                    <Link to={`/createproduct?id=${storeUrlId}`} className="mt-1 ml-2 float-right shadow-none text-capitalize">

                                        <Button variant={'outline-purple'} className={'text-white'}>
                                            Create New Product
                                        </Button>
                                    </Link>

                                    <Link to={`/editstore?id=${storeUrlId}`} className=" mt-1 float-right shadow-none text-capitalize">
                                        <Button variant={'outline-purple'} className={'text-white'}>
                                            <FontAwesomeIcon icon={faPenToSquare} /> Edit
                                        </Button>
                                    </Link>
                                    <Button variant={'outline-purple'} className={'text-white mr-2  mt-1 float-right shadow-none text-capitalize'} onClick={() => handlePausePlay()} >
                                        {storeSellingStaus ? 'Pause' : "Play"}
                                    </Button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <Tabs
                                        defaultActiveKey="home"
                                        transition={false}
                                        id="noanim-tab-example"
                                        className="mb-3 mt-4"
                                    >
                                        <Tab eventKey="home" ClassName="text-white" title="Store Info">
                                            <div className="row text-center">
                                                <div className="col-lg-3 col-md-12 col-sm-6">
                                                    <p className="py-1"> <b style={{ fontWeight: '600' }}> Store Owner </b></p>
                                                    <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0]?.ownerName}</h5>
                                                </div>
                                                <div className="col-lg-3 col-md-12 col-sm-6">
                                                    <p className="py-1"> <b style={{ fontWeight: '600' }}> Address</b></p>
                                                    <p className="py-2">
                                                        {A?.length <= 10 && (
                                                            <span>
                                                                <b style={{ fontWeight: '400' }}>{A}</b>
                                                            </span>
                                                        )}
                                                        {A?.length > 10 && (
                                                            <span>
                                                                <b style={{ fontWeight: '400' }}>{showFullAddress ? A : `${B}....`}</b>
                                                                <button
                                                                    className="btn btn-link btn-sm"
                                                                    onClick={handleViewMoreClick}
                                                                    type="button"
                                                                    style={{
                                                                        textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px',
                                                                    }}
                                                                >
                                                                    {showFullAddress ? '▲' : '▼'}
                                                                </button>
                                                            </span>
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="col-lg-3 col-md-12 col-sm-6">
                                                    <p className="py-1"> <b style={{ fontWeight: '600' }}> Cateogory </b></p>
                                                    <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0]?.categories[0].category_name}</h5>
                                                </div>
                                                <div className="col-lg-3 col-md-12 col-sm-6">
                                                    <p className="py-1 mb-1"> <b style={{ fontWeight: '600' }}> Status</b></p>
                                                    <span
                                                        style={{ textTransform: 'Capitalize' }}
                                                        className={`badge px-3 py-1 mb-2 ${
                                                            // eslint-disable-next-line max-len, no-nested-ternary
                                                            storeData.result[0]?.status === 'rejected' || storeData.result[0]?.status === 'deactivated' ? 'badge-danger' : storeData.result[0]?.status === 'pending' ? 'badge-warning' : 'badge-success'
                                                            }`}
                                                    >
                                                        {storeData.result[0]?.status === 'pending' || storeData.result[0]?.status === 'Pending' ? (
                                                            <h5 className="currentPrice text-dark"> {storeData.result[0]?.status}</h5>
                                                        ) : (
                                                            <h5 className="currentPrice"> {storeData.result[0]?.status}</h5>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Profile" ClassName="text-white" title="Approver Info">
                                            <div className="row text-center">
                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                    <p className="py-2"> <b style={{ fontWeight: '600' }}> Approver Name </b></p>
                                                    {storeData.result[0]?.approver_name[0] ? (
                                                        <h5 style={{ textTransform: 'capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">
                                                            {storeData.result[0]?.approver_name[0].name}
                                                        </h5>
                                                    ) : (
                                                        <h6>-</h6>
                                                    )}
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                    <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}>Store Doc </b></p>
                                                    <Button size="sm" className="btn btn-sm mb-2 px-2" variant="outline-purple">
                                                        <a
                                                            href={storeData.result[0]?.store_document}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="text-white"
                                                        >
                                                            View
                                                        </a>
                                                    </Button>
                                                </div>
                                                {storeData.result[0]?.status === 'approved' && (
                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                        <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Approve Reason </b></p>
                                                        <p className="py-2">
                                                            {A1?.length <= 10 && (
                                                                <span>
                                                                    <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{A1}</b>
                                                                </span>
                                                            )}
                                                            {A1?.length > 10 && (
                                                                <span>
                                                                    <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{showFullAddress1 ? A1 : `${B1}....`}</b>
                                                                    <button
                                                                        className="btn btn-link btn-sm"
                                                                        onClick={handleViewMoreClick1}
                                                                        type="button"
                                                                        style={{
                                                                            textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px', textTransform: 'Capitalize',
                                                                        }}
                                                                    >
                                                                        {showFullAddress1 ? '▲' : '▼'}
                                                                    </button>
                                                                </span>
                                                            )}
                                                        </p>
                                                    </div>
                                                )}
                                                {storeData.result[0]?.status === 'approved' && (
                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                        <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Approve Doc </b></p>
                                                        <Button size="sm" className="btn btn-sm mb-2 px-2" variant="outline-purple">
                                                            <a
                                                                href={storeData.result[0]?.approver_document}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="text-white"
                                                            >
                                                                View
                                                            </a>
                                                        </Button>
                                                    </div>
                                                )}
                                                {storeData.result[0]?.status === 'rejected' && (
                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                        <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Reject Reason </b></p>
                                                        <p className="py-2">
                                                            {A2?.length <= 10 && (
                                                                <span>
                                                                    <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{A2}</b>
                                                                </span>
                                                            )}
                                                            {A2?.length > 10 && (
                                                                <span>
                                                                    <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{showFullAddress2 ? A2 : `${B2}....`}</b>
                                                                    <button
                                                                        className="btn btn-link btn-sm"
                                                                        onClick={handleViewMoreClick2}
                                                                        type="button"
                                                                        style={{
                                                                            textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px', textTransform: 'Capitalize',
                                                                        }}
                                                                    >
                                                                        {showFullAddress2 ? '▲' : '▼'}
                                                                    </button>
                                                                </span>
                                                            )}
                                                        </p>
                                                    </div>
                                                )}
                                                {storeData.result[0]?.status === 'rejected' && (
                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                        <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Reject Doc </b></p>
                                                        <Button size="sm" className="btn btn-sm mb-2 px-2" variant="outline-success">
                                                            <a
                                                                href={storeData.result[0]?.reject_document}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="text-white"
                                                            >
                                                                View
                                                            </a>
                                                        </Button>
                                                    </div>
                                                )}
                                                {storeData.result[0]?.status !== 'rejected' && storeData.result[0]?.status !== 'approved' && (
                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                        <p className="py-2 mb-1"> <b>  </b></p>
                                                        <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2"> </h5>
                                                    </div>
                                                )}
                                                {storeData.result[0]?.status !== 'rejected' && storeData.result[0]?.status !== 'approved' && (
                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                        <p className="py-2 mb-1"> <b>  </b></p>
                                                        <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2"> </h5>
                                                    </div>
                                                )}
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                            <div hidden className="row mt-3 mb-1">
                                <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                                    <b>Store Detail</b>
                                </p>
                            </div>
                            <div hidden className="row px-2 py-1">
                                <div data-aos="fade-up" data-aos-delay="300" className="col-12 text-center CreatorDiv1">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <p className="py-2"> <b style={{ fontWeight: '600' }}> Store Owner </b></p>
                                            <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0]?.ownerName}</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <p className="py-2"> <b style={{ fontWeight: '600' }}> Address</b></p>
                                            <p className="py-2">
                                                {A?.length < 10 && (
                                                    <span>
                                                        <b style={{ fontWeight: '400' }}>{A}</b>
                                                    </span>
                                                )}
                                                {A?.length > 10 && (
                                                    <span>
                                                        <b style={{ fontWeight: '400' }}>{showFullAddress ? A : `${B}....`}</b>
                                                        <button
                                                            className="btn btn-link btn-sm"
                                                            onClick={handleViewMoreClick}
                                                            type="button"
                                                            style={{
                                                                textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px',
                                                            }}
                                                        >
                                                            {showFullAddress ? '▲' : '▼'}
                                                        </button>
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <p className="py-2"> <b style={{ fontWeight: '600' }}> Cateogory </b></p>
                                            <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0]?.categories[0].category_name}</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Status</b></p>
                                            <span
                                                style={{ textTransform: 'Capitalize' }}
                                                className={`badge px-3 py-1 mb-2 ${
                                                    // eslint-disable-next-line max-len, no-nested-ternary
                                                    storeData.result[0]?.status === 'rejected' || storeData.result[0]?.status === 'deactivated' ? 'badge-danger' : storeData.result[0]?.status === 'pending' ? 'badge-warning' : 'badge-success'
                                                    }`}
                                            > <h5 className="currentPrice"> {storeData.result[0]?.status}</h5></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div hidden className="row mt-3 mb-1">
                                <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                                    <b>Approver Detail</b>
                                </p>
                            </div>
                            <div hidden className="row px-2 py-1">
                                <div data-aos="fade-up" data-aos-delay="300" className="col-12 text-center CreatorDiv1">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <p className="py-2"> <b style={{ fontWeight: '600' }}> Store Owner </b></p>
                                            <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0]?.ownerName}</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}>Store Doc </b></p>
                                            <a
                                                href={storeData.result[0]?.store_document}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                                            >
                                                View
                                            </a>
                                        </div>
                                        {storeData.result[0]?.status === 'approved' && (
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Approve Reason </b></p>
                                                <p className="py-2">
                                                    {A1?.length < 10 && (
                                                        <span>
                                                            <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{A1}</b>
                                                        </span>
                                                    )}
                                                    {A1?.length > 10 && (
                                                        <span>
                                                            <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{showFullAddress1 ? A1 : `${B1}....`}</b>
                                                            <button
                                                                className="btn btn-link btn-sm"
                                                                onClick={handleViewMoreClick1}
                                                                type="button"
                                                                style={{
                                                                    textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px', textTransform: 'Capitalize',
                                                                }}
                                                            >
                                                                {showFullAddress1 ? '▲' : '▼'}
                                                            </button>
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                        {storeData.result[0]?.status === 'approved' && (
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Approve Doc </b></p>
                                                <a
                                                    href={storeData.result[0]?.approver_document}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                                                >
                                                    View
                                                </a>
                                            </div>
                                        )}
                                        {storeData.result[0]?.status === 'rejected' && (
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Reject Reason </b></p>
                                                <p className="py-2">
                                                    {A2?.length < 10 && (
                                                        <span>
                                                            <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{A2}</b>
                                                        </span>
                                                    )}
                                                    {A2?.length > 10 && (
                                                        <span>
                                                            <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{showFullAddress2 ? A2 : `${B2}....`}</b>
                                                            <button
                                                                className="btn btn-link btn-sm"
                                                                onClick={handleViewMoreClick2}
                                                                type="button"
                                                                style={{
                                                                    textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px', textTransform: 'Capitalize',
                                                                }}
                                                            >
                                                                {showFullAddress2 ? '▲' : '▼'}
                                                            </button>
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                        {storeData.result[0]?.status === 'rejected' && (
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Reject Doc </b></p>
                                                <a
                                                    href={storeData.result[0]?.reject_document}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                                                >
                                                    View
                                                </a>
                                            </div>
                                        )}
                                        {storeData.result[0]?.status !== 'rejected' && storeData.result[0]?.status !== 'approved' && (
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <p className="py-2 mb-1"> <b>  </b></p>
                                                <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2"> </h5>
                                            </div>
                                        )}
                                        {storeData.result[0]?.status !== 'rejected' && storeData.result[0]?.status !== 'approved' && (
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <p className="py-2 mb-1"> <b>  </b></p>
                                                <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2"> </h5>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-3" style={{ borderTop: '1px solid white', opacity: '1.1' }} />
                            <p style={{ textTransform: 'Capitalize' }} className="mb-2 mt-2">{storeData.result[0]?.storeDescription}</p>
                        </div>
                    </div>
                ) : (
                    <div className="row text-center">
                        <div className="col-lg-4 col-md-12 col-sm-12 py-1 justify-content-center" />
                        <div className="col-lg-4 col-md-12 col-sm-12 py-1 justify-content-center">
                            <p className="py-4">Loading...</p>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 py-1 justify-content-center" />
                    </div>
                )}


                <Product storeId={storeUrlId} onDeleteProduct={handleRefreshProducts} />

                {/* 
        <Row>
          {storeData?.result?.map((store, index) => (
            <Col key={index} xs={12} sm={6} lg={3} className="mb-4">
              <CardContainer>
                <Logo src={store.storeLogo} alt={store.storeName} />
                <StoreName>{store.storeName}</StoreName>
                <StoreInfo>Type: {store.store_type}</StoreInfo>
                <Status status={store.status}>{store.status}</Status>
              </CardContainer>
            </Col>
          ))}
        </Row> 


        {/* <hr className="mt-5 mx-4" style={{ border: '1px solid #4d4d4d', opacity: '1.1' }} />
        <div>
          {storeData ? (
            <h3 style={{ textTransform: 'Capitalize' }} className="text-center py-4">{storeData.result[0]?.storeName} Products</h3>
          ) : (
            <h3 className="text-center py-4"> Products</h3>
          )}

        //   <Product storeUrlId={storeId} onDeleteProduct={handleRefreshProducts} />
        </div> */}

                {/* Your Store Detail Card */}
            </div>
        </>



    );
};

export default StoreDetailById;
const CardContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Logo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 12px;
`;

const StoreName = styled.h5`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const StoreInfo = styled.p`
  font-size: 14px;
  color: #555;
  margin: 0;
`;

const Status = styled.span`
  display: inline-block;
  margin-top: 8px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 16px;
  background-color: ${(props) =>
        props.status === "approved" ? "#d4edda" : "#f8d7da"};
  color: ${(props) => (props.status === "approved" ? "#155724" : "#721c24")};
`;
