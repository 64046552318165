import Modal from "react-bootstrap/Modal";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { editOtcMarketPlacePackage, editOtcMarketPlacePackageGeneral, getAllTokens } from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";

import { Button } from "@/shared/components/Button";
import { useChainContext } from "../../../containers/context/context";

const UpdateGeneralPackageCard = ({
    show,
    hide,
    reFetchPackages,
    otcId,
    items,
}) => {
    const [searchLoad, setsearchLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chain_id, setChain_id] = useState(items?.chain_id);
    const [packageStatus, setpackageStatus] = useState(items?.package_status);
    const [packageChoice, setPackageChoice] = useState(items?.packageChoice);
    const [pool1, setPool1] = useState("");
    const [pool2, setPool2] = useState("");
    const [allTokens, setAllTokens] = useState([]);
    const { setReFetchData, reFethData } = useChainContext()
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [packageToSell, setPackageToSell] = useState(items?.want_sell || "")


    const [allTokensPool2, setAllTokensPool2] = useState([]);
    const [inputId, setInputId] = useState("");
    const [userName, setUserName] = useState("");
    const [searchUserId, setSearchUserId] = useState([]);
    useEffect(() => {
        // Filter and map the allTokens array
        const options = allTokens.map(token => ({
            value: token.symbol,
            label: token.symbol
        }));

        // Update the state with the new dropdown options
        setDropdownOptions(options);
    }, [allTokens]);
    const initialTokenData = dropdownOptions.map((item) => ({
        symbol: null,
        price: null,
    }));

    const [tokenData, setTokenData] = useState(initialTokenData);
    const newArray = tokenData.filter(
        (item) => item !== null && item !== undefined
    );
    // const [prices, setPrices] = useState(() => {
    //     const initialPrices = {};
    //     items?.swap_pairs.forEach((pair) => {
    //         initialPrices[pair.symbol] = pair.price;
    //     });
    //     return initialPrices;
    // });

    const [checkedItems, setCheckedItems] = useState(() => {
        const initialCheckedItems = {};
        dropdownOptions.forEach((item) => {
            initialCheckedItems[item.symbol] = items.swap_pairs.some(
                (responseItem) => responseItem.symbol == item.symbol
            );
        });
        return initialCheckedItems;
    });
    const [pricesArray, setPricesArray] = useState([]);
    // useEffect(() => {
    //     const pricesArrayData = Object.entries(prices).map(([symbol, price]) => ({
    //         symbol,
    //         price,
    //     }));
    //     setPricesArray(pricesArrayData);
    // }, [prices]);
    const [marketplaceInfo, setMarketplaceInfo] = useState({
        packageName: items?.package_name,
        price: items.price,
        token: items.token,
        transfer_fee: items.transfer_fee,
        description: items.package_description,
        contract_address: items.contract_address,
        unlockDay: items.unlock_days,
    });

    const {
        packageName,
        price,
        token,
        description,
        transfer_fee,
        contract_address,

        unlockDay,
    } = marketplaceInfo;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMarketplaceInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };
    useEffect(() => {
        if (items && items.new_pool) {
            const pools = items.new_pool.split("-");
            setPool1(pools[0]);
            setPool2(pools[1]);
        }
    }, [items]);
    // const handleCheckboxChange = (label) => {
    //     setCheckedItems((prevCheckedItems) => ({
    //         ...prevCheckedItems,
    //         [label]: !prevCheckedItems[label],
    //     }));
    // };
    // const handlePriceChange2 = (symbol, newPrice) => {
    //     setPrices((prevPrices) => ({
    //         ...prevPrices,
    //         [symbol]: newPrice,
    //     }));
    // };

    const CreateNewOtcMarketplacePackage = async () => {
        try {
            if (!packageName || !price || !token || !description ) {
                // If any required field is empty, display an error message
                toast.error("Please fill in all required fields");
                return;
            }

            setIsLoading(true);
            const poolVal = [pool1, pool2].join("-");

            const payload = {
                "market_place_id": otcId,
                "package_id": items?._id,
                'package_status': packageStatus,
                "price": price,
                "token": token,
                "package_name": packageName,
                "package_description": description,
                "unlock_days": unlockDay,
                want_sell: packageToSell,
                // "package_choice": packageChoice,
                "pool": poolVal,
                // "owner_id": inputId,
                // "swap_pairs": pricesArray
            }
            const res = await editOtcMarketPlacePackageGeneral(payload);
            toast.success(res.data.Message);
            setIsLoading(false);
            // reFetchPackages();
            setReFetchData(!reFethData);
            hide();
        } catch (error) {
            toast.error(error?.response?.data.Message);
            setIsLoading(false);
        }
    };
    const packageChoiceOptions = [
        { value: "virtual_transfer", label: "Virtual Transfer" },
        { value: "open_contract", label: "Open Contract" },
    ];

    const handleSymbolChange = (idx, event) => {
        const { checked, value } = event.target;
        setTokenData((prevState) => {
            if (checked) {
                const newData = [...prevState];
                newData[idx] = { symbol: value, price: newData[idx]?.price || "" };
                return newData;
            } else {
                const newData = [...prevState];
                newData[idx] = null; // Mark the object as null
                return newData.filter((item) => item !== null); // Filter out null objects
            }
        });
    };

    const fetchAllTokens = async () => {

        const payload = {
            chain_id: "",
            limit: 100,
            page_number: 1
        };
        const res = await getAllTokens(payload);
        setAllTokens(res.data.user);
        const filteredData = res.data.data.filter((item) => item.label !== "BTC");
        setAllTokensPool2(filteredData);
    };
    useEffect(() => {
        fetchAllTokens();
    }, []);
    const handlePriceChange = (idx, event) => {
        const { value } = event.target;
        setTokenData((prevState) => {
            const newData = [...prevState];
            newData[idx] = { ...newData[idx], price: value };
            return newData;
        });
    };

    const packageStatusOptions = [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
    ];

    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={hide}
            >
                <div
                    className="loader-overlay"
                    style={{
                        display: isLoading ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        zIndex: 999,
                    }}
                >
                    <Ring size={65} color="#00897B" />
                </div>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        <b> Update General OTC Marketplace Package</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="d-flex flex-column"
                        style={{ boxShadow: "none", fontFamily: "sans-serif" }}
                    >
                        <div className="d-flex flex-row flex-wrap gap-4 ">
                            <div className="col-12 col-md-4">
                                <label>Package Name</label>
                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        name="packageName"
                                        value={packageName}
                                        className="form-control mt-2"
                                        placeholder="Package Name"
                                        onChange={handleChange}
                                    />
                                </InputDiv>
                            </div>

                            <div className="col-12 col-md-3">
                                <label>Price of package</label>

                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        name="price"
                                        value={price}
                                        className="form-control mt-2"
                                        placeholder=" Enter Token to sell"
                                        onChange={handleChange}
                                    />
                                </InputDiv>
                            </div>
                            <div className=" flex flex-col col-12 col-md-3 ">
                                <label>Package Price Symbol</label>
                                <InputDiv>
                                    <DropdownDiv className="mb-0">
                                        <CustomSelect
                                            // isLoading={searchLoad}
                                            name="receiver"
                                            placeholder={`${pool1 ? pool1 : "Select Symbol"}`}
                                            onChange={(value) => {
                                                //   setFromContractAddress(value.value);
                                                setPool1(value.label);
                                            }}
                                            value={pool1}
                                            options={allTokensPool2}
                                            isSearchable
                                        />
                                    </DropdownDiv>
                                </InputDiv>
                            </div>
                            <div className="col-12 col-md-3">
                                <label>Token to sell</label>

                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        name="token"
                                        value={token}
                                        className="form-control mt-2"
                                        placeholder="Price of package"
                                        onChange={handleChange}
                                    />
                                </InputDiv>
                            </div>




                            <div className=" flex flex-col col-12 col-md-3 ">
                                <label>Token to Sell Symbol</label>
                                <InputDiv>
                                    <DropdownDiv className="mb-0">
                                        <CustomSelect
                                            // isLoading={searchLoad}
                                            name="receiver"
                                            // onInputChange={handleInputChange}
                                            placeholder={`${pool2 ? pool2 : "Select Symbol"}`}
                                            onChange={(value) => {
                                                //   setToContractAddress(value.value);
                                                setPool2(value.label);
                                            }}
                                            value={pool2}
                                            options={dropdownOptions}
                                            isSearchable
                                        />
                                    </DropdownDiv>
                                </InputDiv>
                            </div>






                            <div className="col-12 col-md-3">
                                <label>Unlock Days</label>

                                <InputDiv>
                                    <ChatSearchInput
                                        type="number"
                                        name="unlockDay"
                                        value={unlockDay}
                                        className="form-control mt-2"
                                        placeholder="Enter Unlock Days"
                                        onChange={handleChange}
                                    />
                                </InputDiv>
                            </div>

                            <div className="col-12 col-md-3">
                                <label>Package to Sell</label>

                                <InputDiv>
                                    <ChatSearchInput
                                        type="number"
                                        name="packageToSell"
                                        value={packageToSell}
                                        className="form-control mt-2"
                                        placeholder="Enter Package to Sell"
                                        onChange={(e) => {
                                            e.target.value > 0 ? setPackageToSell(e.target.value) : setPackageToSell(items?.want_sell || "")
                                        }}
                                    />
                                </InputDiv>
                            </div>
                            {/* <div className="col-12 col-md-3 ">
                                <label>Package Choice</label>

                                <InputDiv>
                                    <DropdownDiv className="mb-0">
                                        <CustomSelect
                                            name="packageChoice"
                                            placeholder={`Package Choice`}
                                            onChange={(value) => setPackageChoice(value.value)}
                                            value={packageChoice}
                                            options={packageChoiceOptions}
                                            isSearchable
                                        />
                                    </DropdownDiv>
                                </InputDiv>
                            </div> */}
                            <div className="col-12 col-md-4 col-lg-3">
                                <label>Package Status</label>

                                <InputDiv>
                                    <DropdownDiv className="mb-0">
                                        <CustomSelect
                                            isLoading={searchLoad}
                                            name="receiver"
                                            placeholder={`Package Status`}
                                            onChange={(value) => setpackageStatus(value.value)}
                                            value={packageStatus}
                                            options={packageStatusOptions
                                            }
                                            isSearchable
                                        />
                                    </DropdownDiv>
                                </InputDiv>
                            </div>




                            <div className="col-12 col-md-3">
                                <label>Description</label>

                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        name="description"
                                        value={description}
                                        className="form-control mt-2"
                                        placeholder="Enter Description"
                                        onChange={handleChange}
                                    />
                                </InputDiv>
                            </div>

                        </div>
                        {/* <div className="col-12 w-[100%] gap-4 mt-4 justify-between">
                            {allTokens.map((item, idx) => (
                                <div
                                    className="d-flex gap-4 justify-between w-full items-center"
                                    key={idx}
                                >
                                    <div className="d-flex gap-4">
                                        <input
                                            type="checkbox"
                                            checked={checkedItems[item.label]}
                                            onChange={() => handleCheckboxChange(item.label)}
                                        />

                                        <label className="flex mt-2">{item.label}</label>
                                    </div>
                                    {checkedItems[item.label] && (
                                        <input
                                            type="text"
                                            placeholder="Enter Price"
                                            value={prices[item.label]}
                                            onChange={(e) =>
                                                handlePriceChange2(item.label, e.target.value)
                                            }
                                        />
                                    )}
                                </div>
                            ))}


                        </div> */}
                        <div className="d-flex justify-content-center">
                            <FormButton
                                type="submit"
                                variant="contain-success"
                                className="py-2 mt-2 col-12 col-md-4"
                                onClick={CreateNewOtcMarketplacePackage}
                            >
                                Update Package
                            </FormButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default UpdateGeneralPackageCard;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
