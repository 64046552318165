import { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "@/shared/components/Button";
import { Ring } from "@uiball/loaders";
import { verifyTFAEmail } from "../../../utils/services/user.service";
import { toast } from "react-toastify";

const BuyPackageModal = ({
  show,
  taxSymbol,
  tax,
  hide,
  data,
  onClick,
  isLoading,
}) => {
  const [priceSymbol, secondSymbol] =
    (data?.new_pool !== "" && data?.new_pool?.split("-")) ||
    data?.pool[0]?.pool?.split("-") ||
    data?.pool?.split("-");
  const [isTfaVarified, setIsTfaVarified] = useState(false);
  const userSessionData = JSON.parse(localStorage.getItem("user"));
  const [tfaCode, setTfaCode] = useState("");

  const handleTfaCode = async () => {
    const requestData = {
      token: tfaCode,
      email: userSessionData.data.user.email,
    };

    try {
      // setloading(true);
      const res = await verifyTFAEmail(requestData);

      if (res) {
        // setloading(false);
        if (res.data.verified === false) {
          setTfaCode("");
          toast.error("Incorrect TFA Code", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (res.data.verified === true) {
          setIsTfaVarified(true);
          toast.success("Verify TFA Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // setloading(false);
          setTfaCode("");
          toast.error("Verify TFA failed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        // setloading(false);
        console.error("verifyTFA failed:", res);
      }
    } catch (error) {
      console.log("🚀 ~ handleTfaCode ~ error:", error);

      // setloading(false);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row flex-column gap-4 container ">
            <h5 className="text-black text-start ">
              Are you sure you want to buy this package...?
            </h5>
            <span className="d-flex gap-2  ">
              <h4 className="text-black">
                Package Price:{" "}
                <span
                  style={{
                    fontSize: "8px",
                  }}
                >
                  You Pay
                </span>
              </h4>
              <h4 className="text-black">{`${data?.price} ${priceSymbol}`}</h4>
            </span>
            <span className="d-flex gap-2  ">
              <h4 className="text-black">
                Tokens :{" "}
                <span
                  style={{
                    fontSize: "8px",
                  }}
                >
                  You Receive{" "}
                </span>
              </h4>
              <h4 className="text-black">{`${data?.token} ${secondSymbol}`}</h4>
            </span>
            <span className="d-flex gap-2  ">
              <h4 className="text-black">Tax : </h4>
              <h4 className="text-black">{`${tax} ${taxSymbol}`}</h4>
            </span>
            {userSessionData.data.user.TFA && (
              <div className=" d-flex col-lg-12 col-md-12 col-sm-12 mt-1 gap-2  justify-content-between ">
                <InputDiv>
                  <label htmlFor="email" className="text-white mb-3">
                    Verify TFA *
                  </label>
                  <input
                    name="tfa"
                    type="text"
                    placeholder="Enter Code"
                    className="text-black"
                    value={tfaCode}
                    onChange={(e) => {
                      setTfaCode(e.target.value);
                    }}
                  />
                </InputDiv>

                <InputDiv>
                  <FormButton
                    variant="contain-success"
                    className="py-2 mt-4 w-75 btn-sm"
                    onClick={handleTfaCode}
                    disabled={isTfaVarified}
                  >
                    {isTfaVarified ? "Verified" : "Verify"}
                  </FormButton>
                </InputDiv>
              </div>
            )}

            <div className="d-flex justify-content-start ">
              <FormButton
                type="submit"
                variant="contain-success"
                className="col-lg-4  col-sm-4 mt-1"
                onClick={onClick}
                disabled={!isTfaVarified && userSessionData.data.user.TFA}
              >
                Yes
              </FormButton>
              <FormButton
                type="submit"
                variant="outline-success"
                className="col-lg-4 col-sm-4 mt-1"
                onClick={hide}
              >
                No
              </FormButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BuyPackageModal;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  input {
    background-color: transparent;
    border: none;
    color: white;
    border-bottom: 1px solid #555555;
  }
  input.disabled {
    cursor: not-allowed !important;
    color: #d9d9d9;
  }
`;
