// marketplacepackagecard.js (or PackageCard.js - adjust filename as needed)

import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import styled from "styled-components";
import numeral from "numeral";
import { Card } from "@/shared/components/Card";
import { Button } from "@/shared/components/Button";
import { colorBorder } from "@/utils/palette";
import { ProfileCard } from "./ProfileBasicComponents"; // Adjust path if needed
import BuyPackageModal from "./BuyPackageModal"; // Adjust path if needed
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  buyOtcStripe_overview,
  createpaymentintent,
  getTokenPrice,
} from "../../../utils/services/user.service"; // Adjust path if needed
import { Modal } from "react-bootstrap";
import { useChainContext } from "../../../containers/context/context";

const PackageCard = ({
  cardData,
  onClick,
  isLoading,
  hide,
  tax,
  isDisbale,
  taxSymbol,
}) => {
  let priceSymbol, secondSymbol;
  const [selectedCurrency, setSelectedCurrency] = useState("usd"); // Default to USD

  if (cardData?.pool?.length > 0 && cardData.pool[0]?.pool) {
    [priceSymbol, secondSymbol] = cardData.pool[0].pool.split("-");
  } else if (cardData?.new_pool) {
    [priceSymbol, secondSymbol] = cardData.new_pool.split("-");
  }

  const [show, setShow] = useState(false);
  const [showStripeModal, setShowStripeModal] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [stripeData, setStripeData] = useState("");
  const [eurPrice, setEurPrice] = useState("");
  const { globalTokensLogo } = useChainContext();
  
  const FetchgetTokenPrice = async () => {
    const payload = {
      symbol: "EURI",
    };
    const res = await getTokenPrice(payload);

    setEurPrice(res?.data?.coin_price);
  };
  useEffect(() => [FetchgetTokenPrice()], []);
  
  const handleFiatPayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe or Elements not loaded");
      return;
    }

    setProcessing(true);
    setError(null);
    setSuccess(null);

    try {
      // Call the `buyOtcStripe_overview` API first
      const overviewPayload = {
        package_id: cardData?._id,
        marketplace_id: cardData?.market_place_id, // Replace with actual marketplace ID
      };

      const overviewResponse = await buyOtcStripe_overview(overviewPayload);

      // Check if the API response indicates success
      if (
        !overviewResponse?.data?.success ||
        overviewResponse?.data?.status !== 200
      ) {
        throw new Error(
          overviewResponse?.data?.Message ||
            "Failed to proceed with overview API"
        );
      }

      // Fetch the clientSecret directly
      const payload = {
        amount:
          selectedCurrency === "eur"
            ? (cardData.price / eurPrice) * 100 + tax * 100
            : cardData.price * 100 + tax * 100, // Amount in cents
        currency: selectedCurrency, // Pass selected currency
        package_id: cardData?._id,
        marketplace_id: cardData?.market_place_id,
      };

      const response = await createpaymentintent(payload);

      if (!response?.data?.clientSecret) {
        throw new Error("Failed to get clientSecret from server");
      }

      const clientSecret = response.data.clientSecret;

      const cardElement = elements.getElement(CardElement);

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        setError(result.error.message);
      } else if (result.paymentIntent.status === "succeeded") {
        setSuccess("Payment successful!");
        onClick(result.paymentIntent?.id);
      }
    } catch (err) {
      setError(err.message);
      console.error("Payment error:", err);
    } finally {
      setProcessing(false);
    }
  };
  const matchTokenUrl = (tokenName) => {
    let normalizedTokenName =
      tokenName.toUpperCase() === "USDT (BEP20)"
        ? "tether"
        : tokenName.toLowerCase();
    if (
      normalizedTokenName.includes("(sol)") ||
      normalizedTokenName.includes("(erc20)")
    ) {
      normalizedTokenName = normalizedTokenName.split(" ")[0]; // Extract the base token name
    }

    if (normalizedTokenName === "btc") {
      normalizedTokenName = "bitcoin";
    } else if (tokenName === "CTY") {
      normalizedTokenName = "CustodyCoin";
    }
    if (tokenName === "EUROC") {
      normalizedTokenName = "eurc";
    }
    normalizedTokenName = normalizedTokenName.replace(/[^a-zA-Z0-9]/g, "");
    const matchedToken = globalTokensLogo.find((token) =>
      token.url.toLowerCase().includes(normalizedTokenName)
    );

    return matchedToken?.url || ""; // Return the matched URL or an empty string
  };

  return (
    <>
      <Card>
        <ProfileCard>
          {/* <ProfileInformation> */}
          <div className="d-flex flex-column justify-content-between p-3 py-4 ">
            <ProfileName>{cardData?.package_name}</ProfileName>
            <ProfileWork className="py-1">
              {cardData?.package_description}
            </ProfileWork>

            <div className="d-flex py-2 flex-row justify-content-between">
              {/* Left Side: Price Symbol Image */}
              <img
                src={matchTokenUrl(priceSymbol)}
                alt={priceSymbol}
                style={{ width: "24px", height: "24px" }}
              />
              {/* Middle Icon */}
              <i
                className="fa fa-exchange d-flex text-white"
                aria-hidden="true"
              ></i>
              {/* Right Side: Second Symbol Image */}
              <img
                src={matchTokenUrl(secondSymbol)}
                alt={secondSymbol}
                style={{ width: "24px", height: "24px" }}
              />
            </div>
            <ProfileContact>
              <div className="d-lg-flex flex-column text-uppercase justify-content-between  ">
                <h5 className="d-flex gap-1">
                  Price{" "}
                  <span className="d-flex" style={{ fontSize: "8px" }}>
                    (you pay)
                  </span>
                  :
                </h5>
                <h5>{`${numeral(cardData.price).format("0,0.000")} ${
                  priceSymbol === "SOLANA" ? "SOL" : priceSymbol
                }`}</h5>
              </div>
            </ProfileContact>

            {cardData.unlock_days && (
              <ProfileContact>
                <div className="d-flex  text-uppercase justify-content-between  ">
                  <h5>Unlock Days:</h5>
                  <h5>{`${cardData.unlock_days}`}</h5>
                </div>
              </ProfileContact>
            )}
            <ProfileContact dir="ltr">
              <div className="d-lg-flex flex-column text-uppercase justify-content-between ">
                <h5 className="d-flex gap-1">
                  Token{" "}
                  <span className="d-flex" style={{ fontSize: "8px" }}>
                    (you get)
                  </span>
                  :
                </h5>
                <h5>{`${numeral(cardData.token).format(
                  "0,0.000"
                )} ${secondSymbol}`}</h5>
              </div>
            </ProfileContact>
            <ProfileContact dir="ltr">
              <div className="d-lg-flex text-uppercase justify-content-between align-items-center ">
                <h5>Package Status:</h5>
                <h5
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "400",
                  }}
                  className="currentPrice"
                >
                  <Badge
                    bg={
                      cardData.package_status === "pending"
                        ? "warning"
                        : cardData.package_status === "active"
                        ? "success"
                        : cardData.package_status === "inactive"
                        ? "danger"
                        : "primary"
                    }
                  >
                    {cardData.package_status}{" "}
                  </Badge>
                </h5>
              </div>
            </ProfileContact>

            {priceSymbol === "Fiat Currency" && (
              <FormButton
                type="button"
                variant="outline-success"
                className="py-2 mt-2 w-100"
                onClick={() => {
                  setStripeData(cardData);
                  setShowStripeModal(true);
                }}
              >
                Pay Now
              </FormButton>
            )}

            {priceSymbol !== "Fiat Currency" && (
              <FormButton
                type="submit"
                variant="outline-success"
                className="py-2 mt-2 w-100"
                onClick={() => setShow(true)}
                disabled={isDisbale === "inactive"}
              >
                Buy Package
              </FormButton>
            )}
          </div>
          {/* </ProfileInformation> */}
        </ProfileCard>
      </Card>

      {show && (
        <BuyPackageModal
          show={show}
          hide={() => {
            setShow(false);
          }}
          data={cardData}
          tax={tax}
          taxSymbol={taxSymbol}
          onClick={() => {
            onClick();
            setShow(false);
          }}
          isLoading={isLoading}
        />
      )}

      <Modal show={showStripeModal} onHide={() => setShowStripeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFiatPayment}>
            {/* Currency Selection */}
            <div className="mb-3">
              <label className="d-block">Select Currency:</label>
              <div>
                <label className="me-3">
                  <input
                    type="radio"
                    name="currency"
                    value="usd"
                    checked={selectedCurrency === "usd"}
                    onChange={() => setSelectedCurrency("usd")}
                    className="me-1"
                  />
                  USD
                </label>
                <label>
                  <input
                    type="radio"
                    name="currency"
                    value="eur"
                    checked={selectedCurrency === "eur"}
                    onChange={() => setSelectedCurrency("eur")}
                    className="me-1"
                  />
                  EUR
                </label>
              </div>
            </div>

            {/* Card Element */}
            <CardElement
              options={{
                style: {
                  base: {
                    color: "#424770",
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a",
                  },
                },
              }}
            />

            {/* Pay Now Button */}
            <FormButton
              type="submit"
              variant="outline-success"
              className="py-2 mt-2 w-100"
              disabled={processing}
            >
              {processing ? "Processing..." : "Pay Now"}
            </FormButton>

            {/* Error/Success Messages */}
            {error && <div style={{ color: "red" }}>{error}</div>}
            {success && <div style={{ color: "green" }}>{success}</div>}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PackageCard;
// region STYLES
const ProfileInformation = styled.div`
  padding: 30px 0px;
  display: flex;

  border-bottom: 1px solid ${colorBorder};
  display: flex; /* Add display flex */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */

  @media (max-width: 1345px) and (min-width: 1200px) {
    padding: 30px 0px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;

const ProfileData = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 360px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
`;

const ProfileName = styled.p`
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProfileWork = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
  opacity: 0.6;
  line-height: 18px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProfileContact = styled.span`
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
`;

const ProfileStats = styled.div`
  display: flex;
  justify-content: space-around;
`;

// endregion
