
import React, { useEffect, useState } from "react";
import { Button as newButton } from "@/shared/components/Button";

import styled from "styled-components";

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import {
    Modal as BootstrapModal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "@/shared/components/Panel";
import { ButtonToolbar } from "@/shared/components/Button";
import { flexFlow, paddingLeft, paddingRight } from "@/utils/directions";

import ReactTableBase from "@/shared/components/table/ReactTableBase";
import {
    getAllTokens,
    getContracts,
    getSafebox,

} from "../../../../utils/services/user.service";
import {
    colorAccent,
    colorBackground,
    colorIcon,
    colorText,
    colorWhite,
} from "../../../../utils/palette";

import {
    faEye
} from "@fortawesome/free-regular-svg-icons";

import { Ring } from "@uiball/loaders";
import { getUpdatedContract } from "../../../../utils/services/user.service";
import { Link } from "react-router-dom";
import ContractDetailModal from "../../Operations/Contract/component/ContractDetailModal";
import { Button } from "../../../../shared/components/Button";
const header = [
    { accessor: "name", Header: "Name" },
    { accessor: "action", Header: "Action", disableGlobalFilter: true },
    { accessor: "date", Header: "Date" },
    { accessor: "amount", Header: "Amount" },
    { accessor: "approver", Header: "Approver" },
    { accessor: "hash", Header: "Hash", disableGlobalFilter: true },
    { accessor: "type_of_contract", Header: "Type" },
    { accessor: "symbol", Header: "Symbol" },
    { accessor: "status", Header: "Status" },
    { accessor: "walletType", Header: "Wallet Type" },
    { accessor: "id", Header: "ID" },
];

const RecentContract = ({ title, viewAll, detailShow }) => {
    const [safeboxData, setsafeboxData] = useState([]);
    const [safeboxData1, setsafeboxData1] = useState([]);
    const [mergedArray, setMergedArray] = useState([]);
    const [mergedArray1, setMergedArray1] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpenContract, setModalIsOpenConract] = useState(false);
    const [selectedId, setSelectedId] = useState()

    // console.log('New mergedArray', mergedArray);

    const getContractDetails = async () => {
        setIsLoading(true);
        try {
            const res = await getUpdatedContract()
            setMergedArray(res?.data?.History?.result)
            setIsLoading(false);


        } catch (error) {
            setIsLoading(false);


        }
    };
    const getSafeDetail = () => {
        setIsLoading(true);
        getSafebox()
            .then((res) => {
                setsafeboxData(res.data.data);
                setsafeboxData1(res.data.data);
                setIsLoading(false);
            })
            .catch(() => {
                setsafeboxData([]);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        getContractDetails();
        getSafeDetail();
    }, []);
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };


    const tableConfig = {
        isEditable: false,
        isSortable: false,
        isResizable: false,
        withPagination: true,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: "Search...",
        isPagination: false,
    };



    const getStatusColor = (status1) => {
        switch (status1.toLowerCase()) {
            case "completed":
                return "#4ce1b6";
            case "pending":
                return "#f6da6e";
            case "rejected":
                return "#ff4861";
            case "waiting":
                return "#f6da6e";
            case "declined":
                return "#ff4861";
            case "failed":
                return "#ff4861";
            default:
                return "#70bbfd";
        }
    };



    const data = [];

    mergedArray.map((item) =>
        data.push({
            id: (
                <div className="d-flex flex-row gap-2 align-items-center">
                    {item._id}
                </div>
            ),

            date: item.contract_type === "safebox" ? item.date : item.Date,
            name:
                item.contract_type === "safebox" ? (
                    <h6
                        className="text-capitalize d-flex text-nowrap"
                        style={{ color: "#999999", fontSize: "inherit" }}
                    >
                        {item.name}
                    </h6>
                ) : (
                    <h6
                        className="text-capitalize text-nowrap"
                        style={{ color: "#999999", fontSize: "inherit" }}
                    >
                        {item.Name}
                    </h6>
                ),
            hash:
                item.contract_type === "safebox" ? (

                    <HashLink
                        role="link"
                        onClick={() =>
                            openInNewTab(`https://bscscan.com/tx/${item.hash[0]}`)
                        }
                    >
                        {" "}
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </HashLink>
                ) : (
                    <HashLink
                        role="link"
                        onClick={() =>
                            openInNewTab(`https://bscscan.com/tx/${item.hash}`)
                        }
                    >
                        {" "}
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </HashLink>
                ),

            type_of_contract: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {item.contract_type}
                </h6>
            ),
            amount: item.contract_type === "safebox" ? "-" : `${item.Amount}`,
            symbol: item.contract_type === "safebox" ? "-" : `${item.Symbol === 'USDT' ? "USDT (BEP20)" : item?.Symbol}`,
            status: (
                <span
                    className="badge px-2 py-1 text-capitalize"
                    style={{ backgroundColor: getStatusColor(item.status) }}
                >
                    <h6 className="text-white">
                        <b>{item.status}</b>
                    </h6>
                </span>
            ),
            walletType:
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {item.trx_status || "Virtual"}
                </h6>
            ,
            approver: (
                <div style={{ display: "contents", background: "red" }}>
                    {item.contract_type === "safebox" ? (
                        <h6
                            className="text-capitalize"
                            style={{ color: "#999999", fontSize: "inherit" }}
                        >
                            {item.approvers[0]?.approver?.name}
                        </h6>
                    ) : (
                        <h6
                            className="text-capitalize"
                            style={{ color: "#999999", fontSize: "inherit" }}
                        >
                            {item.Approver ? item.Approver?.name : "-"}
                        </h6>
                    )}
                </div>
            ),
            action: (
                // <button
                //     className="btn btn-primary purple-button btn-sm ml-2 px-2 shadow-none"
                //     type="button"
                //     style={{ marginTop: "0px" }}
                //     onClick={() => {
                //         setSelectedId(item._id)
                //         setModalIsOpenConract(true)
                //     }}
                // >
                //     <span className="text-purple text-xs font-weight-bold">
                //         View
                //     </span>
                // </button>
                <Button
                    onClick={() => {
                        setSelectedId(item._id)
                        setModalIsOpenConract(true)
                    }}
                    size="md"
                    className="py-2 mt-2 transform-lowercase text-white"
                    variant="outline-purple"
                >
                    View
                </Button>

            )


        })
    );
    return (
        <>

            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <Panel
                lg={12}
                title="Recent Contract"
                refresh={isLoading}
                // isLoading={isLoading}
                refreshRequest={() => getContractDetails()}
            >
                <Link to={'/contractslist'}>
                    <FormButton variant={"outline-primary"}>View All </FormButton></Link>
                <ReactTableBase
                    columns={header}
                    data={detailShow ? data.slice(0, 3) : data}
                    tableConfig={tableConfig}
                />
                {data?.length === 0 && <NoResult>(0) Results! Data Not Found</NoResult>}
            </Panel>

            {
                modalIsOpenContract &&

                <ContractDetailModal selectedId={selectedId} closeContractsDetails1={() => setModalIsOpenConract(false)} modalIsOpen={modalIsOpenContract} />
            }

        </>
    );
};
export default RecentContract;
const FormButton = styled(newButton)`
  color: "white";
  margin-top: 8px;
`;
const MediaButton = styled(newButton)`
  color: "white";
  margin-top: 0px;
  padding: 3px 10px;
  margin-bottom: 0px;
`;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  button {
    min - width: 100px;
  padding: 4px 25px;
  margin-bottom: 0;
  display: inline-block;
}

  flex-flow: ${flexFlow} !important;
  `;
export const ModalMainDiv = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  overflow-wrap: break-word;
  align-items: baseline;
`;
export const ModalTitle = styled.h4`
  background: #4ce1b6;
  padding: 5px;
  color: #232329;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size:20px;
  &:first-child {
    margin - top: 0;
}
  `;
export const ModalDetail = styled.h5`
  width:300px;
  margin-bottom: 10px;
  font-size:16px;
  font-weight: 700;
  text-align:left;
  word-wrap: break-word;
  &:first-child {
    margin - top: 0;
}
  `;
export const ModalSubTitle = styled.h4`
  width: 100px;
  margin-top: 10px;
  font-weight: 600;
  font-size:16px;
  text-align:left;
  word-wrap: break-word;
  color:${colorAccent};
  &:first-child {
    margin - top: 0;
}
  `;
export const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;
const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;
export const NoResult = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-top: 1rem;
  margin-left: 0rem;
  color: white;
  font-weight: 700;
`;
export const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margintop: 0.5rem;
  height: 9rem;
`;
export const ModalHeader = styled.div``;
export const ModalBody = styled.div``;
const getColor = (color) => {
    switch (color) {
        case "success":
            return "#22bb33";
        case "danger":
            return "#ff4861";
        default:
            return "#f88e7d";
    }
};
export const StyledModal = styled(BootstrapModal).withConfig({
    shouldForwardProp: (prop) => !["colored", "header"].includes(prop),
})`

                                .modal-dialog {
                                  max - width: 450px;
}

                                .modal-content {
                                  border - radius: 0;
                                border: none;
                                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                                position: relative;
                                padding: 50px 40px 60px 40px;
                                text-align: center;
                                background-color: ${colorBackground};
                                color: ${colorText};
}

                                ${ModalTitleIcon} {
                                  color: ${(props) => getColor(props.color)};
}

                                ${(props) =>
        props.colored &&
        `
color: ${colorWhite};

${ModalTitle},
${ModalTitleIcon},
${ModalCloseButton} {
 color: ${colorWhite};
}

${ModalFooter} {

 button:first-child {
   color: ${colorWhite};
   background-color: rgba(255, 255, 255, 0.3);
   border-color: ${colorWhite};

   &:before {
     background-color: rgba(255, 255, 255, 0.2);
   }
 }

 button:last-child {
   border-color: white;
   color: ${colorWhite};
 }
}

.modal-content {
 color: ${colorWhite};
 background-color: ${getColor(props.color)};
}
`}

                                ${(props) =>
        props.header &&
        `

.modal-dialog {
 max-width: 520px;
}

.modal-content {
 padding: 0;
 text-align: left};
}

${ModalTitle} {
 color: ${colorWhite};
}

${ModalHeader} {
 color: ${colorWhite};
 padding: 15px 20px;
 position: relative;
}

${ModalTitle} {
 margin: 0;
 font-weight: 300;
}

${ModalCloseButton} {
 color: ${colorWhite};
 top: calc(50% - 8px);
}

${ModalBody} {
 padding-top: 25px;
 padding-bottom: 20px;
 ${paddingRight(props)}: 40px;
 ${paddingLeft(props)}: 20px;
}

${ModalFooter} {
 margin-bottom: 40px;
 justify-content: flex-end;
 ${paddingRight(props)}: 20px;
}

${ModalHeader} {
 background-color: ${getColor(props.color)};
}
`}
                                `;
