/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Ring } from "@uiball/loaders";
// import { Form, Field } from 'react-final-form';
import { Field, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FormButtonToolbar } from "@/shared/components/form/FormElements";
import { Button } from "@/shared/components/Button";
import {
  MaterialFormContainer,
  MaterialTextField,
  MaterialFormLabel,
} from "@/shared/components/form/MaterialFormElements";
import Panel from "@/shared/components/Panel";
import {
  SendTwoFactor,
  getUserInfo,
  getBecomeApprover,
  create,
  verifyTFA,
  tfaEnable,
  tfaDisable,
  changePassword,
  sendOtpDynamic,
} from "../../../../utils/services/user.service";
import PinWithInfoWindow from "../../../../shared/Maps/GoogleMap/components/PinWithInfoWindow";
// import { changePassword, clearMsg } from '../../../../redux/actions/authActions';

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  initialValue,
  disabled,
  type,
}) => (
  <MaterialTextField
    label={label}
    error={touched && error}
    value={input.value || initialValue}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
    disabled={disabled}
    type={type}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

renderTextField.defaultProps = {
  meta: null,
  label: "",
  initialValue: "",
  disabled: false,
  type: "text",
};

const ProfileSettings = ({ onSubmit }) => {
  const [userInfo, setUserInfo] = useState([]);
  const [load, setLoad] = useState(false);
  const [twoFactors, setTwoFactor] = React.useState(false);
  const [twoFactors2, setTwoFactor2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setloading] = useState(false);
  const [status, setStatus] = useState(200);
  const [isOpen, setisOpen] = useState(false);
  const [oldPasswordval, setOldPassword] = useState("");
  const [newPasswordval, setNewPassword] = useState("");
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);

  const getUsers = () => {
    setLoad(true);
    getUserInfo()
      .then((res) => {
        // console.log('getUsers >>>>>', res);
        if (res.status === 200) {
          // console.log('res', res);
          setLoad(false);
          setUserInfo(res.data.User);
          setTwoFactor(res.data.User?.status_2fa);
          setTwoFactor2(res.data.User?.TFA === true);
        } else {
          setLoad(false);
          setUserInfo(JSON.parse(localStorage.getItem("user")));
          setTwoFactor(userInfo?.data?.user?.status_2fa);
        }
      })
      .catch(() => {
        setUserInfo(JSON.parse(localStorage.getItem("user")));
        setLoad(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);


  const getOtpCode = async () => {
    try {
      setIsLoading(true);
      const payload = {
        type: 'change_password',
      };
      const res = await sendOtpDynamic(payload);
      toast.success(res?.data?.message);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message || 'Something went wrong');
    }
  };
  const handleSendOtp = async () => {
    if (!oldPasswordval || !newPasswordval) {
      toast.error("Please enter both Old Password and New Password before requesting OTP", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setIsLoading(true);
    const payload = {
      type: "change_password",
    };

    try {
      const res = await sendOtpDynamic(payload);

      if (res?.status === 201) {
        toast.success("OTP sent successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsOtpSent(true);
      } else {
        toast.error("Failed to send OTP. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Error sending OTP. Please check your network or try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    if (!otp) {
      toast.error("Please enter the OTP to proceed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const requestData = {
      password: oldPasswordval,
      newPassword: newPasswordval,
      otp,
    };

    setIsLoading(true);

    try {
      const response = await changePassword(requestData);

      if (response.status === 200) {
        toast.success("Password Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOldPassword("");
        setNewPassword("");
        setOtp("");
        setIsOtpSent(false);
      } else {
        toast.error(`Error Updating Password: ${response.statusText}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error Updating Password:", error);
      toast.error(error.response?.data?.message || "Error Updating Password", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <form>
        <div className="mb-3">
          <label>Old Password</label>
          <ChatSearchInput
            type="password"
            value={oldPasswordval}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder="Enter old password"
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label>New Password</label>
          <ChatSearchInput
            type="password"
            value={newPasswordval}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
            className="form-control"
          />
        </div>

        {isOtpSent && (
          <div className="mb-3">
            <label>OTP</label>
            <ChatSearchInput
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              className="form-control"
            />
          </div>
        )}

        <div className="row mb-4">
          <div className="col-md-12 col-lg-4 col-sm-12" />
          <div className="col-md-12 col-lg-4 col-sm-12">
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {!isOtpSent ? (
                <Button
                  variant="outline-primary"
                  className="px-5"
                  type="button"
                  onClick={handleSendOtp}
                  disabled={isLoading}
                >
                  {isLoading ? "Sending OTP..." : "Get OTP Code"}
                </Button>
              ) : (
                <Button
                  variant="outline-primary"
                  className="px-5"
                  type="button"
                  onClick={handleUpdateProfile}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading" : "Update Password"}
                </Button>
              )}
            </div>
          </div>
          <div className="col-md-12 col-lg-4 col-sm-12" />
        </div>
      </form>


      <div className="col-12 d-flex flex-column  w-100 ">
        <PinWithInfoWindow />
      </div>
    </>
  );
};

ProfileSettings.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ProfileSettings;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;
