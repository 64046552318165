import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure Bootstrap is imported

import Panel from "@/shared/components/Panel";
import { Ring } from "@uiball/loaders";
import { buyProjectOtcpackage, checkOTCRequestApproved, getAllOtcPackages, getAllProjectOtc, getProject, submitAccessRequest, submitOTCAccessRequest } from "../../../utils/services/user.service";
import { Button } from "../../../shared/components/Button";
import CreateProjectOTCModal from "./CreateProjectOTC";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import UpdateProjectOTCModal from "./UpdateProjectOTCModal";
import CreateProjectOTCPackage from "./CreteProjectOTCPackage";
import ProjectOTCPackageCard from "./ProjectOTCPackageCard";
import { useChainContext } from "../../../containers/context/context";
import { useLocation, useParams } from "react-router";
import InfoItem from "../../../shared/components/OtcMarketplace/InfoItem";
import FeeComponent from "../../../shared/components/OtcMarketplace/FeeComponent";
import StatusComponent from "../../../shared/components/StatusCompoent/StatusComponent";
import { toast } from "react-toastify";

const ProjectOtc = ({ project_Owner_id, userId, currentStatusOtc }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [showCreateOTC, setShowCreateOtc] = useState(false)
    const [showEditOTC, setShowEditOtc] = useState(false)
    const [showCreatePackage, setShowCreatePackage] = useState(false)
    const [allProjectOTC, setAllProjectOTC] = useState([])
    const [reFetch, setRefetch] = useState(true)
    const [allPackages, setAllPackages] = useState([])
    const [otcAccess, setOtcAccess] = useState('')
    const location = useLocation()
    const userRole = JSON.parse(localStorage.getItem("user"))?.data.user.role;



    // Extract the `id` from the query string
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get("id");
    const { reFethData,
        setGlobalProjectOtcId, } = useChainContext()

    const fetchAllPackages = async () => {

        try {
            const payload = {
                otc_id: allProjectOTC?._id,
                type: userId === project_Owner_id ? "my_package" : ""
            }
            const res = await getAllOtcPackages(payload)
            setAllPackages(res?.data?.packages)
        } catch (error) {
            console.log("🚀 ~ fetchAllPackages ~ error:", error)

        }
    }

    const checkOTCRequest = async () => {
        setIsLoading(true)

        try {
            const payload = {
                otc_id: allProjectOTC?._id
            }
            const res = await checkOTCRequestApproved(payload)

            setOtcAccess(res?.data?.data)
            setIsLoading(false)

        } catch (error) {
            console.log("🚀 ~ checkOTCRequest ~ error:", error)
            setIsLoading(false)

        }
    }


    useEffect(() => {
        fetchAllPackages()
        if (allProjectOTC?._id) {
            checkOTCRequest()
        }

    }, [allProjectOTC?._id, reFethData, reFetch])

    const getAllProjectOTC = async () => {
        const payload = {
            limit: 10000000,
            page_number: 1,
            project_id: projectId
        }
        const res = await getAllProjectOtc(payload)
        setAllProjectOTC(res?.data?.response[0])
        setGlobalProjectOtcId(res?.data?.response[0]?._id)
    }
    useEffect(() => {
        if (projectId) {

            getAllProjectOTC()
        }
    }, [reFetch, projectId])


    const tableConfig = {
        isEditable: false,
        isSortable: false,
        isResizable: false,
        withPagination: true,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: "Search...",
        isPagination: true,
    };

    const header = [
        { accessor: "market_place_name", Header: "Project OTC Name" },
        { accessor: "buy_fee", Header: "Buy Fee" },
        { accessor: "buy_fee_type", Header: "Buy Fee Type" },
        { accessor: "buy_status", Header: "Buy Status" },
        { accessor: "pool", Header: "Pool" },
        { accessor: "status", Header: "Status" },
        { accessor: "tax_token_symbol", Header: "Tax Token Symbol" },
        // {
        //     accessor: "action",
        //     Header: "Action",
        //     disableGlobalFilter: true,
        // },
    ];
    const data = [];
    if (allProjectOTC) {
        const item = allProjectOTC; // Since it's a single object, no map is needed
        data.push({
            market_place_name: item.market_place_name,
            buy_fee: item.buy_fee,
            buy_fee_type: item.buy_fee_type,
            buy_status: item.buy_status,
            pool: item.pool,
            status: item.status,
            tax_token_symbol: item.tax_token_symbol,

        });
    }

    const accessRequest = async () => {

        setIsLoading(true)
        try {
            const payload = {
                id: allProjectOTC?._id,
                type: "otc"
            }
            const res = await submitAccessRequest(payload)
            toast.success(res?.data?.Message)
            setIsLoading(false)
        } catch (error) {
            toast.error(error?.response?.data?.Message)
            setIsLoading(false)

        }
    }

    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>

            {/* <h3>OTC</h3> */}
            {userId === project_Owner_id &&
                <div className="d-flex flex-column flex-md-row gap-1 align-items-center">
                    {!allProjectOTC &&
                        <Button onClick={() => setShowCreateOtc(true)} variant={'outline-primary'}>Create  OTC</Button>
                    }
                    {allProjectOTC &&
                        <Button onClick={() => setShowEditOtc(true)} variant={'outline-primary'}>Edit Project OTC</Button>
                    }
                    {allProjectOTC &&
                        <Button onClick={() => setShowCreatePackage(true)} variant={'outline-primary'}>Create Project Package</Button>
                    }
                </div>}

            {allProjectOTC && (currentStatusOtc == "approved" || userId === project_Owner_id) ? (
                <><div className="row text-center">
                    {/* Pool ID */}
                    <InfoItem label="Pool ID" value={allProjectOTC._id} />

                    {/* Project OTC Name */}
                    <InfoItem label="Name" value={allProjectOTC.market_place_name} />

                    {/* Status */}
                    <InfoItem label="Status" value={allProjectOTC.status} />

                    {/* Buy Status */}
                    <StatusComponent title="Buy Status" status={allProjectOTC.buy_status} />

                    {/* Swapping Status */}
                    {/* <StatusComponent title="Swapping Status" status={allProjectOTC.swapping_status} /> */}

                    {/* Transfer Status */}
                    {/* <StatusComponent title="Transfer Status" status={allProjectOTC.transfer_status} /> */}

                    {/* Pool */}
                    {/* <InfoItem
    label="Pool"
    value={
        allProjectOTC.swap_pairs && allProjectOTC.swap_pairs.length > 0
            ? `${allProjectOTC.swap_pairs[0].symbol || "--"}-${allProjectOTC.tax_token_symbol || "--"}`
            : "--"
    }
/> */}

                    {/* Tax Token Symbol */}
                    {/* <InfoItem label="Tax Token" value={allProjectOTC.tax_token_symbol || "--"} /> */}

                    {/* Fees */}
                    <FeeComponent
                        title="Buy Fee"
                        fee={allProjectOTC?.buy_fee}
                        type={allProjectOTC?.buy_fee_type} />
                    {/* <FeeComponent
        title="Swap Fee"
        fee={allProjectOTC?.swap_fee}
        type={allProjectOTC?.swap_fee_type}
    />
    <FeeComponent
        title="Transfer Fee"
        fee={allProjectOTC?.transfer_fee}
        type={allProjectOTC?.transfer_fee_type}
    /> */}
                </div><div className="row">
                        {userId === project_Owner_id ? (
                            allPackages.map((item, index) => (
                                <div key={index} className="col-12 col-md-12 col-xl-4 mb-4">
                                    <ProjectOTCPackageCard
                                        type="owner"
                                        data={item}
                                        marketplace_id={allProjectOTC?._id} />
                                </div>
                            ))
                        ) : (
                            allPackages.map((item, index) => (
                                <div key={index} className="col-12 col-md-12 col-xl-4 mb-4">
                                    <ProjectOTCPackageCard
                                        type="buyers"
                                        data={item}
                                        marketplace_id={allProjectOTC?._id} />
                                </div>
                            ))
                        )}

                    </div></>
            ) : (
                allProjectOTC ? (
                    <div>
                        {
                            userRole == 'project' ?
                                <><h5>You don't have OTC Access. Please get OTC Access.</h5><Button variant="outline-primary" onClick={accessRequest}>
                                    Get Access
                                </Button></> : <p>OTC only for project role</p>
                        }

                    </div>
                ) : (
                    <p>No Record Found</p>
                )
            )}

            {/* <FeeComponent
                title="Buy Fee"
                fee={allProjectOTC?.buy_fee}
                type={allProjectOTC?.buy_fee_type}
            /> */}


            {/* <FeeComponent
                    title="Swap Fee"
                    fee={allProjectOTC?.swap_fee}
                    type={allProjectOTC?.swap_fee_type}
                />


                <FeeComponent
                    title="Transfer Fee"
                    fee={allProjectOTC?.transfer_fee}
                    type={allProjectOTC?.transfer_fee_type}
                /> */}


            {/* </div> : <h5>No Data Found!</h5>} */}





            <CreateProjectOTCModal show={showCreateOTC} reFetch={() => setRefetch(!reFetch)} hide={() => setShowCreateOtc(false)} />
            <CreateProjectOTCPackage show={showCreatePackage} hide={() => setShowCreatePackage(false)} reFetchPackages={() => setRefetch(!reFetch)} marketplace_id={allProjectOTC?._id} />
            <UpdateProjectOTCModal show={showEditOTC} reFetch={() => setRefetch(!reFetch)} hide={() => setShowEditOtc(false)} data={allProjectOTC} />
        </>
    );
};

export default ProjectOtc;
