import React, { useState } from "react";
import { Badge, Col } from "react-bootstrap";
import styled from "styled-components";
import MessageTextOutlineIcon from "mdi-react/MessageTextOutlineIcon";
import { Card } from "@/shared/components/Card";
import { Button } from "@/shared/components/Button";
import { colorAdditional, colorBlue, colorBorder } from "@/utils/palette";
import { paddingLeft, left } from "@/utils/directions";
import { ProfileCard } from "../../../containers/Account/Profile/ProfileBasicComponents";
import UpdateProjectOTCPackage from "./UpdateProjectOTCPackage";
import BuyPackageModal from "../../../shared/components/OtcMarketplace/BuyPackageModal";
import { buyProjectOtcpackage } from "../../../utils/services/user.service";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useChainContext } from "../../../containers/context/context";


const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const ProjectOTCPackageCard = ({ data, marketplace_id, type, reFetchPackages, otcId }) => {
  const { globalTokensLogo } = useChainContext()
  let priceSymbol, secondSymbol;
  if (data?.pool?.length > 0 && data?.pool) {
    [priceSymbol, secondSymbol] = data.pool.split("-");
  } else if (data?.new_pool) {
    [priceSymbol, secondSymbol] = data.new_pool.split("-");
  } const [show, setShow] = useState(false);

  const [showBuyModal, setShowBuyModal] = useState(false);

  const hanldeBuyPackage = async () => {
    // console
    // setIsLoading(true)
    try {
      const payload = {
        package_id: data._id, project_otc_id: marketplace_id
      }
      const res = await buyProjectOtcpackage(payload)
      toast.success(res?.data?.Message)
      setShowBuyModal(false)

    } catch (error) {
      toast.error(error.response.data.Message)
      console.log("🚀 ~ hanldeBuyPackage ~ error:", error)

    }
  }

  // Function to match URL based on token name
  const matchTokenUrl = (tokenName) => {
    let normalizedTokenName = tokenName.toUpperCase() === "USDT (BEP20)"
      ? "tether"
      : tokenName.toLowerCase();
    if (normalizedTokenName.includes("(sol)") || normalizedTokenName.includes("(erc20)")) {
      normalizedTokenName = normalizedTokenName.split(" ")[0]; // Extract the base token name
    }

    if (normalizedTokenName === "btc") {
      normalizedTokenName = "bitcoin";
    }
    else if (tokenName === "CTY") {
      normalizedTokenName = "CustodyCoin";
    }
    if (tokenName === "EUROC") {
      normalizedTokenName = "eurc";
    }
    normalizedTokenName = normalizedTokenName.replace(/[^a-zA-Z0-9]/g, "");
    const matchedToken = globalTokensLogo.find(token =>
      token.url.toLowerCase().includes(normalizedTokenName)
    );

    return matchedToken?.url || ""; // Return the matched URL or an empty string
  };

  return (
    <Card>
      <ProfileCard>
        <div className="d-flex flex-column justify-content-between p-3 py-4 ">
          {/* <ProfileData> */}
          <ProfileName>{data?.package_name}</ProfileName>
          <ProfileWork>{data?.package_description}</ProfileWork>

          <div className="d-flex py-2 flex-row justify-content-between">
            {/* Left Side: Price Symbol Image */}
            <img
              src={matchTokenUrl(priceSymbol)}
              alt={priceSymbol}
              style={{ width: "24px", height: "24px" }}
            />
            {/* Middle Icon */}
            <i className="fa fa-exchange d-flex text-white" aria-hidden="true"></i>
            {/* Right Side: Second Symbol Image */}
            <img
              src={matchTokenUrl(secondSymbol)}
              alt={secondSymbol}
              style={{ width: "24px", height: "24px" }}
            />
          </div>


          <ProfileContact>
            <div className="d-flex text-uppercase justify-content-between align-items-center ">
              <h5 className="d-flex flex-column flex-md-row gap-0 gap-md-1">Package Price: <span style={{
                fontSize: "8px"
              }}>(You Get)</span></h5>
              <h5>{`${data?.price?.toFixed(3)} ${priceSymbol == "SOLANA" ? "SOL" : priceSymbol}`}</h5>
            </div>
          </ProfileContact>
          {data.unlock_days && <ProfileContact>
            <div className="d-flex text-uppercase justify-content-between align-items-center ">
              <h5>Unlock Days:</h5>
              <h5>{`${data.unlock_days}`}</h5>
            </div>
          </ProfileContact>}
          <ProfileContact dir="ltr">
            <div className="d-flex text-uppercase justify-content-between align-items-center ">
              <h5 className="d-flex flex-column flex-md-row gap-0 gap-md-1">Sell Token: <span style={{
                fontSize: "8px"
              }}>(You Pay)</span></h5>
              <h5>{`${data.token} ${secondSymbol}`}</h5>
            </div>
          </ProfileContact>
          <ProfileContact dir="ltr">
            <div className="d-flex text-uppercase justify-content-between align-items-center ">
              <h5 className="flex text-nowrap "> package status:</h5>
              <h5
                style={{
                  textTransform: "capitalize",
                  fontWeight: "400",
                }}
                className="currentPrice"
              >
                <Badge
                  bg={
                    data.package_status === "pending"
                      ? "warning"
                      : data.package_status === "active"
                        ? "success"
                        : data.package_status === "inactive"
                          ? "danger"
                          : "primary"
                  }
                >
                  {data.package_status}{" "}
                </Badge>
              </h5>
            </div>
          </ProfileContact>
          {/* </ProfileData> */}
        </div>
        <div className="d-flex flex-column flex-md-row px-2">
          <FormButton
            type="submit"
            variant="contain-success"
            className="py-2 mt-2 w-100"
            onClick={() => {
              type == "owner" ?
                setShow(true) : setShowBuyModal(true)
            }}
          >
            {type == "owner" ? "Edit Package" : "Buy Package"}

          </FormButton>


        </div>
      </ProfileCard>

      {data && <BuyPackageModal show={showBuyModal} onClick={hanldeBuyPackage} hide={() => setShowBuyModal(false)} data={data} />
      }


      <UpdateProjectOTCPackage data={data} marketplace_id={marketplace_id} show={show} hide={() => setShow(false)} />

    </Card>
  );
};

export default ProjectOTCPackageCard;

// region STYLES

const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;

const ProfileData = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 360px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
`;

const ProfileName = styled.p`
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
  align-items: center;
  display: flex;

  flex-direction: row;
  justify-content: center;
`;

const ProfileWork = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProfileContact = styled.p`
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
`;
