import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure Bootstrap is imported

import Panel from "@/shared/components/Panel";
import { Ring } from "@uiball/loaders";
import { checkMyAccess, getAccessRequest, getAccessRequestProject, getProject, submitAccessRequest, updateAccessRequestProject } from "../../../utils/services/user.service";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import moment from "moment";
import { getStatusBadge } from "../../../utils/constants/contants";
import { Button } from "../../../shared/components/Button";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";

const ProjectAccess = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [accessRequestData, setAccessRequestData] = useState([])
    const [checkUserProjectAcess, setCheckUserProjectAcess] = useState(null)

    const [currentStatus, setCurrentStatus] = useState("")
    const location = useLocation()
    // Extract the `id` from the query string
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get("id");
    const fetchAccessRequest = async () => {
        setIsLoading(true)
        try {
            const payload = { limit: 100000, page_number: 1, email: "", project_id: projectId }

            const res = await getAccessRequestProject(payload)
            setAccessRequestData(res?.data?.data)
            setIsLoading(false)

        } catch (error) {

            setIsLoading(false)

        }
    }
    useEffect(() => {
        if (projectId) {

            fetchAccessRequest()
            checkUserAccess()
        }
    }, [projectId])

    const checkUserAccess = async () => {
        try {
            const payload = {
                id: projectId
            }
            const res = await checkMyAccess(payload)
            const projectObject = res?.data?.access_data?.find(item => item.type === "project");

            // Set the currentStatus if the object exists
            if (projectObject) {
                setCurrentStatus(projectObject.status);
            }
            setCheckUserProjectAcess(res?.data)


        } catch (error) {
            console.log("🚀 ~ checkUserAccess ~ error:", error)

        }
    }
    const accessRequest = async () => {
        setIsLoading(true)

        try {
            const payload = {
                id: projectId,
                type: "project"
            }
            const res = await submitAccessRequest(payload)
            toast.success(res?.data?.Message)
            checkUserAccess()
            setIsLoading(false)
        } catch (error) {
            toast.error(error?.response?.data?.Message)
            setIsLoading(false)

        }
    }
    const Ava = `/img/avat.png`;

    const tableConfig = {
        isEditable: false,
        isSortable: false,
        isResizable: false,
        withPagination: true,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: "Search...",
        isPagination: true,
    };

    const header = [
        { accessor: "project_id", Header: "Project Id" },
        { accessor: "name", Header: "Name" },
        { accessor: "status", Header: "Status" },
        {
            accessor: "action",
            Header: "Action",
            disableGlobalFilter: true,
        },
    ];
    const data = [];

    // Check if checkUserProjectAcess?.data exists and is an object
    if (checkUserProjectAcess?.data) {
        const item = checkUserProjectAcess?.data[0]; // Since it's a single object, no map is needed
        data?.push({
            project_id: item?._id,
            name: (

                <span className="d-flex text-nowrap justify-content-center">
                    {item?.name}
                </span>
            ),

            status: (
                <span className="d-flex flex-row align-items-center w-100 text-center justify-content-center">
                    {getStatusBadge(currentStatus == "" ? 'pending' : currentStatus)}
                </span>
            ),
            action: (
                <Button variat={'outline-primary'} disabled={currentStatus == "pending" || currentStatus == "approved"} onClick={accessRequest}>Get Access</Button>
            )
        });
    }


    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <Panel lg={12} title="Access Request" closeBtn minusBtn refreshLoad>


                <div>
                    <Button variat={'outline-primary'} disabled={currentStatus == "pending" || currentStatus == "approved"} onClick={accessRequest}>Get Access</Button>

                </div>
                {/* <ReactTableBase
                    columns={header}
                    data={data}
                    tableConfig={tableConfig}
                /> */}
                {/* {accessRequestData.length == 0 && <h5>No Request Found!</h5>} */}


            </Panel>
        </>
    );
};

export default ProjectAccess;
